import { TableCell, TableRow, Typography, makeStyles } from "@material-ui/core";
import React from "react";

const options = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

const useStyles = makeStyles((theme) => ({
  tableRow: {
    width: "100%",
    overflow: "hidden",
    // inner shadow
  },

  tableCell: {
    padding: 0,
  },

  infosContainer: {
    fontSize: "0.9em",
    color: "#242424",
    backgroundColor: "#e6e6e6",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "10px 30px",
    boxShadow: "inset 0px 0px 10px 0px rgba(0,0,0,0.25)",
  },

  info: {
    minWidth: "200px",
    marginRight: "30px",
  },

  section: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "20px",
  },

  sectionTitle: {
    width: "100%",
  },
}));

const TicketInfos = ({ ticketInfos }) => {
  const classes = useStyles();

  return (
    <>
      <TableRow className={classes.tableRow}>
        <TableCell className={classes.tableCell} colSpan={12}>
          <div className={classes.infosContainer}>
            <div className={classes.section}>
              <Typography variant="h6" className={classes.sectionTitle}>
                Informações do Ticket
              </Typography>

              <p className={classes.info}>
                <strong>Criado em:</strong>{" "}
                {new Date(ticketInfos?.createdAt)?.toLocaleDateString(
                  "pt-BR",
                  options
                )}
              </p>
              <p className={classes.info}>
                <strong>Atualizado em:</strong>{" "}
                {new Date(ticketInfos?.updatedAt)?.toLocaleDateString(
                  "pt-BR",
                  options
                )}
              </p>
              <p className={classes.info}>
                <strong>Número:</strong> {ticketInfos?.contact?.number}
              </p>
              {ticketInfos?.endTicket?.length > 0 && (
                <p className={classes.info}>
                  <strong>Motivo de finalização:</strong>{" "}
                  {`${ticketInfos?.endTicket[0]?.option} - ${ticketInfos?.endTicket[0]?.body}`}
                </p>
              )}
              <p className={classes.info}>
                <strong>Grupo:</strong> {ticketInfos?.isGroup ? "Sim" : "Não"}
              </p>
              <p className={classes.info}>
                <strong>Canal:</strong> {ticketInfos?.contact?.channel}
              </p>
            </div>

            <div className={classes.section}>
              <Typography variant="h6" className={classes.sectionTitle}>
                Campos personalizados do contato
              </Typography>

              {
                ticketInfos?.contact?.extraInfo?.filter(
                  (extraInfo) => extraInfo.ticketId === null
                ).length === 0 && (
                  <p className={classes.info}>
                    <strong>Nenhum campo personalizado do contato</strong>
                  </p>
                )
              }

              {
                ticketInfos?.contact?.extraInfo
                  ?.filter((extraInfo) => extraInfo.ticketId === null)
                  .map((info) => (
                    <p className={classes.info} key={info.id}>
                      <strong>{info.name}:</strong> {info.value}
                    </p>
                  ))
              }
            </div>

            <div className={classes.section}>
              <Typography variant="h6" className={classes.sectionTitle}>
                Campos personalizados do ticket
              </Typography>

              {ticketInfos?.contact?.extraInfo?.filter(
                (extraInfo) => extraInfo.ticketId === ticketInfos.id
              ).length === 0 && (
                <p className={classes.info}>
                  <strong>Nenhum campo personalizado do ticket</strong>
                </p>
              )}

              {ticketInfos?.contact?.extraInfo
                ?.filter((extraInfo) => extraInfo.ticketId === ticketInfos.id)
                .map((info) => (
                  <p className={classes.info} key={info.id}>
                    <strong>{info?.name}:</strong> {info.value}
                  </p>
                ))}
            </div>

            <div className={classes.section}>
              <Typography variant="h6" className={classes.sectionTitle}>
                Etiquetas
              </Typography>

              {ticketInfos?.contact?.tags?.length === 0 && (
                <p className={classes.info}>
                  <strong>Nenhuma etiqueta</strong>
                </p>
              )}

              <p className={classes.info}>
                {ticketInfos?.contact?.tags?.map((tag) => tag?.name).join(", ")}
              </p>
            </div>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TicketInfos;
