import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { useAuthContext } from "../../context/Auth/AuthContext";
import { useSettingsContext } from '../../context/Settings';
import toastError from "../../errors/toastError";
import useMixpanel from "../../hooks/useMixpanel";
import api from "../../services/api";
import SelectInput from "./SelectInput";
import { useStyles } from "./styles";

export const ContactFilters = ({ fetchContacts, filters, setFilters }) => {
  const classes = useStyles();
	const mixpanel = useMixpanel();
  const { users, user } = useAuthContext();
  const { getSettingValue } = useSettingsContext();

  const [usersList, setUsersList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [extraFields, setExtraFields] = useState([]);

  const channelOptions = [
    { id: 0, name: "Whatsapp", value: "whatsapp" },
    { id: 1, name: "Facebook", value: "facebook" },
    { id: 2, name: "Instagram", value: "instagram" },
  ];

  const isGroupOptions = [
    { id: 0, name: "Sim", value: "true" },
    { id: 1, name: "Não", value: "false" },
  ];

  useEffect(() => {
    const usersList = users?.users?.map((user) => ({
      id: user.id,
      name: user?.name,
      value: user.id,
    }));

    if (
      usersList &&
      (["admin", "supervisor"].includes(user?.profile) ||
        getSettingValue("showPocketContacts") === "true")
    ) {
      setUsersList([
        { id: 0, name: "Sem atendente", value: null },
        ...usersList,
      ]);
    } else {
      setUsersList([
        {
          id: user.id,
          name: user?.name,
          value: user.id,
        },
      ]);
      // setFilters({ ...filters, user: [user?.id] });
    }
  }, [users, user, getSettingValue]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/tags/");
        const tagsList = data?.map((tag) => ({
          id: tag.id,
          name: tag?.name,
          value: tag.id,
        }));

        setTagsList(tagsList);
      } catch (err) {
        console.log(err);
        toastError(err);
      }
    })();

    (async () => {
      try {
        const { data: fields } = await api.get("/extrainfo/field");
        setExtraFields(fields);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  const searchContacts = () => {
    const sanitizedFilters = {
      email: filters.email,
      name: filters?.name,
      number: filters.number,
      isGroup: filters.isGroup,
      tagIds: filters.tag,
      userIds: filters.user,
      channels: filters.channel,
      extraFields: Object.entries(filters.extraFields).map(([key, value]) => {
        if (value !== "" && value !== null) {
          return {
            fieldName: key,
            fieldValue: value,
          };
        }
        return null;
      }),
      createdAtStart: filters.createdAtStart
        ? filters.createdAtStart : undefined,
      createdAtEnd: filters.createdAtEnd
        ? filters.createdAtEnd : undefined,
    };

    fetchContacts(sanitizedFilters);
		mixpanel.track('Contacts Filtered');
  };

  const handleFilterChange = (filterName, value) => {
    setFilters({ ...filters, [filterName]: value });
  };

  const handleFieldsFilterChange = (filterName, value) => {
		if (value.length === 0) {
			const newFilters = { ...filters };
			delete newFilters.extraFields[filterName];
			setFilters(newFilters);
			return;
		}
    setFilters({
      ...filters,
      extraFields: {
        ...filters.extraFields,
        [filterName]: value,
      },
    });

    if (Array.isArray(value) && value.length === 0) {
      const { [filterName]: removedKey, ...newExtraFields } = filters.extraFields;
      setFilters({
        ...filters,
        extraFields: newExtraFields,
      });
    }
  };

  const sanitizeFieldsOptions = (options) => {
    return options.map((option) => ({
      id: option.id,
      name: option.value,
      value: option.id,
    }));
  };

  return (
    <Box
      className={classes.container}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          searchContacts();
        }
      }}
    >
      <div className={classes.filterContainer}>
        <Typography variant="h6" className={classes.filtersTitle}>
          Filtros:
        </Typography>

        <TextField
          label="Nome"
          variant="outlined"
          size="small"
          className={classes.textInput}
          onChange={(e) => handleFilterChange("name", e.target.value)}
        />

        <TextField
          label="Número"
          variant="outlined"
          size="small"
          className={classes.textInput}
          onChange={(e) => handleFilterChange("number", e.target.value)}
        />

        <TextField
          label="Email"
          variant="outlined"
          size="small"
          className={classes.textInput}
          onChange={(e) => handleFilterChange("email", e.target.value)}
        />

        <SelectInput
          parameter="channel"
          parameterName="Canal"
          options={channelOptions}
          onChange={handleFilterChange}
          selectedValues={filters["channel"]}
          multiple
        />

        <SelectInput
          parameter="user"
          parameterName="Atendente Recorrente"
          options={usersList}
          onChange={handleFilterChange}
          selectedValues={filters["user"]}
          multiple
        />

        <SelectInput
          parameter="tag"
          parameterName="Tag"
          options={tagsList}
          onChange={handleFilterChange}
          selectedValues={filters["tag"]}
          multiple
        />

        <SelectInput
          parameter="isGroup"
          parameterName="Grupo"
          options={isGroupOptions}
          onChange={handleFilterChange}
          selectedValues={filters["isGroup"]}
        />

        {extraFields
          ?.filter((field) => field.context === "contact")
          .map((field) => {
            if (field.type === "singleOption") {
              return (
                <SelectInput
                  key={field.id}
                  parameter={field?.name}
                  parameterName={field?.name}
                  options={sanitizeFieldsOptions(field.options)}
                  onChange={handleFieldsFilterChange}
                  selectedValues={filters.extraFields[field?.name]}
                />
              );
            }
            return null;
          })}

        {extraFields
          ?.filter((field) => field.context === "contact")
          .map((field) => {
            if (field.type === "multiOption") {
              return (
                <SelectInput
                  key={field.id}
                  parameter={field?.name}
                  parameterName={field?.name}
                  options={sanitizeFieldsOptions(field.options)}
                  onChange={handleFieldsFilterChange}
                  selectedValues={filters.extraFields[field?.name]}
                  multiple
                />
              );
            }
            return null;
          })}

        {extraFields
          ?.filter((field) => field.context === "contact")
          .map((field) => {
            if (field.type === "text") {
              return (
                <TextField
                  key={field.id}
                  label={field?.name}
                  variant="outlined"
                  size="small"
                  className={classes.textInput}
									value={filters.extraFields[field?.name] || ''}
                  onChange={(e) =>
                    handleFieldsFilterChange(field?.name, e.target.value)
                  }
                />
              );
            }
            return null;
          })}
      </div>

      <Divider orientation="vertical" className={classes.divider} />

      <div className={classes.filterSecondPart}>
        <div className={classes.filterDate}>
          <TextField
            label="Data de criação inicial"
            variant="outlined"
            size="small"
            className={classes.textInput}
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => handleFilterChange("createdAtStart", e.target.value)}
						value={filters.createdAtStart || ''}
          />

          <TextField
            label="Data de criação final"
            variant="outlined"
            size="small"
            className={classes.textInput}
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => handleFilterChange("createdAtEnd", e.target.value)}
						value={filters.createdAtEnd || ''}
          />
        </div>

        <div className={classes.filterButtons}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              setFilters({
                email: "",
                name: "",
                number: "",
                isGroup: [],
                tag: [],
                user: [],
                channel: [],
                extraFields: {},
                createdAtStart: "",
                createdAtEnd: "",
              })
            }
            className={classes.clearFiltersBtn}
          >
            Limpar Filtros
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => searchContacts()}
            className={classes.searchBtn}
          >
            Procurar
          </Button>
        </div>
      </div>
    </Box>
  );
};
