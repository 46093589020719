import React, {  useState } from "react";
import ReactQuill from 'react-quill';

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

import {
  Button,
  TextField,
  Select,
  MenuItem,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";


import { i18n } from "../../translate/i18n";
import CustomerSelect from '../CustomerSelect';
import { useAdminContext } from '../../context/AdminContext';
import { useStyles } from "./styles";

const CustomerSchema = Yup.object().shape({
  title: Yup.string().optional(),
  message: Yup.string().required("A mensagem é obrigatório"),
  type: Yup.string().required("O tipo de aviso é obrigatório"),
  severity: Yup.string().optional(),
  url: Yup.string().optional().matches(
    /^(https?):\/\/[^\s/$.?#].[^\s]*$/,
    "Por favor, insira o endereço corretamente https://..."
  ),
});

const initialState = {
  title: "",
  message: "",
  url: "",
  type: "",
  severity: ""
};

const modules = {
	toolbar: [
		[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
		['bold', 'italic', 'underline','strike', 'blockquote'],
		[
			{'list': 'ordered'}, 
			{'list': 'bullet'}, 
			{'indent': '-1'}, 
			{'indent': '+1'},
			{'script': 'sub'}, 
			{'script': 'super'}
		],
		['link', 'code', 'video'],
		['clean']
	],
};

const formats = [
	'header',
	'bold', 'italic', 'underline', 'strike', 'blockquote',
	'list', 'bullet', 'indent',
	'link', 'image', 'video',
	'code',
	'clean'
];

/**
 * @typedef {object} NoticeModalParams
 * @property {boolean} open
 * @property {function} onClose
 *
 * @param {NoticeModalParams} params
 * @returns
 */

function NoticeModal({ open, onClose }) {
  const classes = useStyles();
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const { createNotice, noticeLoading } = useAdminContext();
  const { status, action } = noticeLoading;
  const isLoading = status === "loading";
  const createAction = action === "create";

  const handleClose = () => {
    onClose();
    setSelectedCustomers([]);
  };

  const handleSubmit = async (values) => {
    values.customers = selectedCustomers;
    await createNotice(values);
    handleClose();
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" scroll="paper">
      <DialogTitle id="form-dialog-title">
        {`${i18n.t("noticeModal.title.notice")}`}
      </DialogTitle>
      <Formik
        initialValues={initialState}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={CustomerSchema}
      >
        {({ values, errors, touched }) => (
          <Form>
            <DialogContent dividers>
              <CustomerSelect
                selectedCustomers={selectedCustomers}
                onChange={(values) => setSelectedCustomers(values)}
              />

              <Field
                as={Select}
                fullWidth
                displayEmpty
                error={touched.type && Boolean(errors.type)}
                className={classes.textField}
                variant="outlined"
                name="type"
                style={{ margin: "12px 0 4px" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                renderValue={() => {
                  const isError = touched.type && Boolean(errors.type);
                  const color = isError ? "#F44336" : "#0000008A";
                  const emptyMessage = isError ? errors.type : "Selecione um tipo";

                  if (values.type === "") {
                    return <span style={{ color }}>{emptyMessage}</span>
                  } else if (values.type === "popup") {
                    return <span>Popup</span>
                  } else {
                    return <span>Header</span>
                  }
                }}
              >
                <MenuItem dense value="popup">
                  <ListItemText primary="Popup" />
                </MenuItem>
                <MenuItem dense value="header">
                  <ListItemText primary="Header" />
                </MenuItem>
              </Field>

							<Field
								as={TextField}
								label={i18n.t("noticeModal.form.title")}
								name="title"
								error={touched.title && Boolean(errors.title)}
								helperText={touched.title && errors.title}
								variant="outlined"
								margin="dense"
								multiline
								className={classes.textField}
							/>

              {values.type === "header" && (
								<>
									<Field
										as={Select}
										fullWidth
										displayEmpty
										error={touched.severity && Boolean(errors.severity)}
										className={classes.textField}
										variant="outlined"
										name="severity"
										style={{ margin: "12px 0 4px" }}
										MenuProps={{
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "left",
											},
											transformOrigin: {
												vertical: "top",
												horizontal: "left",
											},
											getContentAnchorEl: null,
										}}
										renderValue={() => {
											const color = "#0000008A";
											switch (values.severity) {
												case "error":
													return <span>Erro</span>
												case "warning":
													return <span>Alerta</span>
												case "info":
													return <span>Informação</span>
												case "success":
													return <span>Sucesso</span>
												default:
													return <span style={{ color }}>Selecione a gravidade da mensagem</span>
											}
										}}
									>
										<MenuItem value="error">
											<Alert style={{ width: "100%" }} severity="error">Erro</Alert>
										</MenuItem>
										<MenuItem value="warning">
											<Alert style={{ width: "100%" }} severity="warning">Alerta</Alert>
										</MenuItem>
										<MenuItem value="info">
											<Alert style={{ width: "100%" }} severity="info">Informação</Alert>
										</MenuItem>
										<MenuItem value="success">
											<Alert style={{ width: "100%" }} severity="success">Sucesso</Alert>
										</MenuItem>
									</Field>

									<Field
										as={TextField}
										label={i18n.t("noticeModal.form.message")}
										name="message"
										error={touched.message && Boolean(errors.message)}
										helperText={touched.message && errors.message}
										variant="outlined"
										margin="dense"
										multiline
										minRows={4}
										className={classes.textField}
									/>
								</>
              )}

              {values.type === "popup" && (
								<>
									<div style={{height: '300px', marginBottom: '50px'}}>
										<ReactQuill 
											theme="snow"   
											onChange={(e) => {
												values.message = e;
											}}
											modules={modules}
											formats={formats}
											className={classes.quill}
										/>
									</div>
								</>
              )}

							<Field
								as={TextField}
								label={i18n.t("noticeModal.form.url")}
								name="url"
								error={touched.url && Boolean(errors.url)}
								helperText={touched.url && errors.url}
								variant="outlined"
								margin="dense"
								className={classes.textField}
								placeholder="https://"
							/>

            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="secondary"
                disabled={isLoading && createAction}
                variant="outlined"
              >
                {i18n.t("noticeModal.buttons.cancel")}
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={isLoading && createAction}
                variant="contained"
                className={classes.btnWrapper}
              >
                {`${i18n.t("noticeModal.buttons.okNotify")}`}
                {(isLoading && createAction) && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default NoticeModal;
