import React from "react";

import { makeStyles } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles(theme => ({
	customTableCell: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
}));

const TableRowSkeleton = ({ avatar, columns, emptyColumnIndex }) => {
	const classes = useStyles();
	return (
		<TableRow>
			{avatar && (
				<>
					<TableCell style={{ paddingRight: 0 }}>
						<Skeleton
							animation="wave"
							variant="circle"
							width={40}
							height={40}
						/>
					</TableCell>
					<TableCell>
						<Skeleton animation="wave" height={30} width={80} />
					</TableCell>
				</>
			)}
			{Array.from({ length: columns }, (_, index) => (
				<TableCell
					padding={index === emptyColumnIndex ? "none" : undefined}
					style={index === emptyColumnIndex ? { width: 0 } : undefined}
					align="center"
					key={index}
				>
					<div className={classes.customTableCell}>
						<Skeleton
							align="center"
							animation="wave"
							height={index !== emptyColumnIndex ? 30 : 0}
							width={index !== emptyColumnIndex ? "100%" : 0}
						/>
					</div>
				</TableCell>
			))}
		</TableRow>
	);
};

export default TableRowSkeleton;
