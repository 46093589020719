import mixpanel from "mixpanel-browser";
import { getCompany } from "../../helpers/getCompany";

const useMixpanel = () => {
  const company = getCompany();
  const eventQueue = new Set();

  const track = (event, properties) => {
    if (company) {
      const eventKey = JSON.stringify({ event, properties });

      if (!eventQueue.has(eventKey)) {
        mixpanel.track(event, properties);

        eventQueue.add(eventKey);

        setTimeout(() => {
          eventQueue.delete(eventKey);
        }, 5000);
      }
    }
  };

  const identify = (id) => {
    if (company) {
      mixpanel.identify(`${company}-${id}`);
    }
  };

  return {
    track,
    identify,
  };
};

export default useMixpanel;
