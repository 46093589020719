import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import AttachFile from "@material-ui/icons/AttachFile";

import { useStyles } from "./styles";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { dataReducer } from "../../reducers/data";

function QuickAnswerSelect(params) {
  const { searchParam, handleClick, isOpen, height } = params;
  const classes = useStyles();
  const lastQuickAnswer = useRef(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [quickAnswers, dispatch] = useReducer(dataReducer, []);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const fetchQuickAnswers = useCallback(async () => {
    try {
      const { data } = await api.get("/quickAnswers", {
        params: { searchParam, pageNumber },
      });
      setHasMore(data.hasMore);
      dispatch({ type: "LOAD_DATA", payload: data.quickAnswers });
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, [searchParam, pageNumber]);

  const searchQuickAnswers = useCallback(async () => {
    try {
      const { data } = await api.get("/quickAnswers", {
        params: { searchParam },
      });
      setHasMore(data.hasMore);
      dispatch({ type: "SEARCH_DATA", payload: data.quickAnswers });
      setPageNumber(1);
      setLoading(false);
    } catch (err) {
      toastError(err)
    }
  }, [searchParam]);

  useEffect(() => {
    if (hasMore && pageNumber > 1) {
      setLoading(true);
      fetchQuickAnswers();
    }
  }, [pageNumber, hasMore, fetchQuickAnswers]);

  useEffect(() => {
    if (isOpen) {
      dispatch({ type: "RESET_DATA" });
      setLoading(true);
      const delayDebounceFn = setTimeout(() => {
        searchQuickAnswers();
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchParam, isOpen, searchQuickAnswers]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setPageNumber(prevPage => prevPage + 1);
      }
    });
    if (lastQuickAnswer.current) {
      intersectionObserver.observe(lastQuickAnswer.current);
    }
    return () => intersectionObserver.disconnect();
  }, [quickAnswers]);

  if (!isOpen || (!quickAnswers.length && !loading)) return null;

  return (
    <ul className={classes.messageQuickAnswersWrapper} style={{ maxHeight: height }}>
      {quickAnswers.map((value, index, arr) => (
        <li
          ref={index === arr.length - 1 && hasMore ? lastQuickAnswer : undefined}
          key={index}
          onClick={() => handleClick(value)}
        >
          {value.mediaUrl && <AttachFile />}
          <span>
            {`${value.shortcut} - ${value.message}`}
          </span>
        </li>
      ))}
      {loading && <li data-loading className={loading ? classes.circularLoading : classes.circularLoadingHide}><CircularProgress /></li>}
    </ul>
  )
}

export default QuickAnswerSelect;
