import { useReducer, useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";

import { dataReducer } from "../../reducers/data";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { i18n } from '../../translate/i18n';

const loadingState = {
  status: "idle",
  action: "idle",
}

function useNotices() {
  const [noticeLoading, setLoading] = useState(loadingState);
  const [notices, dispatchNotices] = useReducer(dataReducer, []);
  const [pageNumber, setNoticesPageNumber] = useState(1);
  const [hasMoreNotices, setHasMore] = useState(false);

  const fetchNotices = useCallback(async () => {
    setLoading({
      status: "loading",
      action: "read",
    });
    try {
      const { data } = await api.get("/notices", {
        params: { pageNumber },
      });
      setHasMore(data.hasMore);
      dispatchNotices({ type: "LOAD_DATA", payload: data.notices });
    } catch (err) {
      toastError(err);
    }
    setLoading(loadingState);
  }, [pageNumber])

  const createNotice = async (values) => {
    setLoading({
      status: "loading",
      action: "create",
    });
    const payload = {
      ...values,
    }
    try {
      let referenceMessage = "allSuccess";
      const { data } = await api.post("/notify-customers", payload);

      if (data.errorCustomers.length) {
        referenceMessage = "someSuccess";
        setTimeout(() => {
          data.errorCustomers.forEach((error) => {
            toast.error(
              `Erro de "${error.message.toUpperCase()}" na empresa cliente ${error.customer?.name}`,
              {
                toastId: error.customer.id,
                autoClose: false,
              }
            );
          });
        }, 500);
      }
      if (data.notice) {
        toast.success(i18n.t(`noticeModal.toasts.${referenceMessage}`));
        dispatchNotices({ type: "UPDATE_DATA", payload: data.notice });
      }
    } catch (err) {
      console.log({ err });
      toastError(err);
    }
    setLoading(loadingState);
  }

  const updateNotice = async (values) => {
    setLoading({
      status: "loading",
      action: "update",
    });
    const payload = {
      id: values.id,
      isActive: !values.isActive,
    }
    try {
      const { data } = await api.put(`/notice/${payload.id}`, payload);
      toast.success(`${i18n.t("noticeModal.toasts.edited")}`);
      dispatchNotices({ type: "UPDATE_DATA", payload: data.notice });
    } catch (err) {
      toastError(err);
    }
    setLoading(loadingState);
  }

  const deleteNotice = async (id) => {
    setLoading({
      status: "loading",
      action: "delete",
    });
    try {
      await api.delete(`/notice/${id}`);
      toast.success(`${i18n.t("noticeModal.toasts.deleted")}`);
      dispatchNotices({ type: "DELETE_DATA", payload: id });
    } catch (err) {
      toastError(err);
    }
    setLoading(loadingState);
  }

  useEffect(() => {
    fetchNotices();
  }, [pageNumber, fetchNotices]);

  return {
    noticeLoading,
    notices,
    hasMoreNotices,
    setNoticesPageNumber,
    createNotice,
    updateNotice,
    deleteNotice
  };
}

export default useNotices;
