export function getCompany() {
  const { hostname } = window.location;
  const [companyIdentifier] = hostname.split('.');

  if (companyIdentifier.includes('localhost')) {
    return null
  }

  return companyIdentifier;
}
