import React, { useState } from "react";
import { Switch, Route, useRouteMatch, NavLink, Redirect } from "react-router-dom";

import { Container, IconButton, Paper, Typography } from "@material-ui/core";
import { AddCircleOutline, AddAlertOutlined, Business, NotificationsActive } from "@material-ui/icons";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import CustomerModal from "../../components/CustomerModal";
import NoticeModal from "../../components/NoticeModal";
import { AdminProvider } from "../../context/AdminContext";
import { useStyles } from "./styles";
import CustomerTable from '../../components/CustomerTable';
import NoticeTable from '../../components/NoticeTable';

function Admin() {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [noticesModalOpen, setNoticesModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handleOpenCustomerModal = (customer) => {
    setCustomerModalOpen(true);
    setSelectedCustomer(customer);
  }

  const handleCloseCustomerModal = () => {
    setCustomerModalOpen(false);
    setSelectedCustomer(null);
  }

  const handleToggleNoticesModal = () => {
    setNoticesModalOpen(prevState => !prevState);
  };

  return (
    <AdminProvider>
      <MainContainer>
        <CustomerModal
          open={customerModalOpen}
          onClose={handleCloseCustomerModal}
          customer={selectedCustomer}
        />
        <NoticeModal open={noticesModalOpen} onClose={handleToggleNoticesModal} />

        <MainHeader>
          <Title>Painel de administração</Title>
        </MainHeader>

        <Container className={classes.container}>
          <Paper className={classes.paper}>
            <Typography variant="body1">Adicionar uma nova empresa cliente</Typography>
            <IconButton onClick={() => handleOpenCustomerModal()}>
              <AddCircleOutline />
            </IconButton>
          </Paper>

          <Paper className={classes.paper}>
            <Typography variant="body1">Enviar uma nova notificação</Typography>
            <IconButton onClick={handleToggleNoticesModal}>
              <AddAlertOutlined />
            </IconButton>
          </Paper>

          <Container className={classes.navLinkContainer}>
            <NavLink
              exact
              to={path}
              className={classes.navLink}
              activeClassName={classes.isRouteActive}
            >
              <Business fontSize="large" />
              <Typography variant="h6">Clientes</Typography>
            </NavLink>

            <NavLink
              exact
              to={`${path}/notices`}
              className={classes.navLink}
              activeClassName={classes.isRouteActive}
            >
              <NotificationsActive fontSize="large" />
              <Typography variant="h6">Notificações</Typography>
            </NavLink>
          </Container>

          <Switch>
            <Route exact path={path}>
              <CustomerTable handleEdit={handleOpenCustomerModal} />
            </Route>

            <Route exact path={`${path}/notices`}>
              <NoticeTable />
            </Route>

            <Route path="*">
              <Redirect to={path} />
            </Route>
          </Switch>
        </Container>
      </MainContainer>
    </AdminProvider>
  )
}

export default Admin;
