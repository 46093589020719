import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import api from "../../services/api";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  paper: {
    // height: "fit-content",
    width: "100%",
  },
  formControl: {
    width: "100%",
    margin: "15px 0",
  },
  items: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "0",
  },
  select: {
    "& button": {
      display: "none",
    },
  },
}));

export default function EndTicketModal({
  open,
  handleClose,
  ticketId,
  contactId,
  whatsappId,
  closeTicket,
  queue,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [personName, setPersonName] = useState("");

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  const postData = (e) => {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.target).entries());
    data["input-motivo"].length > 0
      ? api
          .post("/end-tickets", {
            option: data["input-motivo"],
            body: data["input-body"],
            ticketId,
            contactId,
            whatsappId,
          })
          .then(() => toast.success("Feedback Enviado"))
          .then(() => {
            history.push("/tickets");
            closeTicket();
          })
          .catch(() => toast.error("Erro ao enviar feedback"))
      : toast.error("Escolha um motivo para finalizar");
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.paper}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">Finalizar Atendimento</DialogTitle>
        <Formik>
          <Form onSubmit={postData}>
            <DialogContent dividers>
              <Typography variant="subtitle1" gutterBottom>
                Observação
              </Typography>
              <FormControl className={classes.formControl}>
                <InputLabel id="input-name-label">Motivo</InputLabel>
                <Select
                  labelId="input-name-label"
                  id="input-motivo"
                  name="input-motivo"
                  value={personName}
                  onChange={handleChange}
                  input={<Input />}
                  className={classes.select}
                >
                  {queue?.motives.map((motive) => (
                    <MenuItem
                      key={motive.id}
                      value={motive?.name}
                      className={classes.items}
                    >
                      <p style={{ margin: "0px" }}>{motive?.name}</p>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  id="input-body"
                  name="input-body"
                  label="Texto"
                  variant="outlined"
                  multiline
                  rows={2}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                variant="outlined"
                type="button"
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <Button color="primary" variant="outlined" type="submit">
                Finalizar
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </div>
  );
}
