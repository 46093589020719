import CssBaseline from "@material-ui/core/CssBaseline";
import mixpanel from "mixpanel-browser";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { unstable_trace as trace } from 'scheduler/tracing';

import App from "./App";
import { getCompany } from "./helpers/getCompany";

if (getCompany()) {
	mixpanel.init('bb6e395c530b987d2c297afbb85b0144', {
		track_pageview: true,
		persistence: 'localStorage'
	})
}

trace('initial render', performance.now(), () => {
  ReactDOM.render(
  <CssBaseline>
		<BrowserRouter>
    	<App />
		</BrowserRouter>
  </CssBaseline>,
  document.getElementById('root'));
});

// ReactDOM.render(
//   <CssBaseline>
//     <App />
//   </CssBaseline>,
//   document.getElementById("root")
// );
