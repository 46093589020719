import React, { createContext, useCallback, useContext, useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import useMixpanel from "../../hooks/useMixpanel";
import api from "../../services/api.js";

const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const { loading, user, isAuth, handleLogin, handleLogout, notice } = useAuth();

  const [onlineUsers, setOnlineUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [queues, setQueues] = useState([]);
  const [tags, setTags] = useState([]);
  const [connections, setConnections] = useState([]);
  const [connectionsMeta, setConnectionsMeta] = useState([]);
  const [listRefresh, setListRefresh] = useState(0);
  const [relatorio, setRelatorio] = useState("disabled");
  const [timeoutUnqueued, setTimeoutUnqueued] = useState(0);
  const [isSocketConnected, setIsSocketConnected] = useState(true);
  const [selectedUsersIds, setSelectedUsersIds] = useState([]);
  const isAdminClient = process.env.REACT_APP_ADMIN_CLIENT === "true";
	const [previousPath, setPreviousPath] = useState("");

	const location = useLocation();
	const mixpanel = useMixpanel();

  useEffect(() => {
    setPreviousPath(window.location.href);
  }, [location]);

	useEffect(() => {
		if (user.id && previousPath !== window.location.href) {
			mixpanel.track("Page View", {
				"Page Title": window.document.title,
				"Referrer": previousPath,
			});
		}
	}, [location, mixpanel, user.id, previousPath])

  const getUsers = useCallback(async () => {
    if (isAuth) {
      try {
        const { data } = await api.get("/users/");
        setUsers(data);
      } catch (err) {
        console.log(err);
      }
    }
  }, [isAuth]);

  const getQueues = useCallback(async () => {
    if (isAuth) {
      try {
        const { data } = await api.get("/queue/");
        setQueues(data);
      } catch (err) {
        console.log(err);
      }
    }
  }, [isAuth]);

  const getTags = useCallback(async () => {
    if (isAuth) {
      try {
        const { data } = await api.get("/tags/");
        setTags(data);
      } catch (err) {
        console.log(err);
      }
    }
  }, [isAuth]);

  const getConnections = useCallback(async () => {
    const whatsAppConnection = await api.get("/whatsapp");
    const metaConnection = await api.get("/meta");
    setConnections(whatsAppConnection.data);
    setConnectionsMeta(metaConnection.data);
  }, []);

  useEffect(() => {
    isAuth && getUsers();
  }, [isAuth, getUsers]);

	useEffect(() => {
		isAuth && getConnections();
	}, [isAuth, getConnections]);

  useEffect(() => {
    isAuth && getQueues();
  }, [isAuth, getQueues]);

  useEffect(() => {
    if (user.id) {
      mixpanel.identify(`${user.id}`);
    }
  }, [mixpanel, user.id]);

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        notice,
        isAuth,
        handleLogin,
        handleLogout,
        onlineUsers,
        setOnlineUsers,
        users,
        getUsers,
        getQueues,
        queues,
        tags,
        getTags,
        connections,
        getConnections,
        listRefresh,
        setListRefresh,
        relatorio,
        setRelatorio,
        timeoutUnqueued,
        isSocketConnected,
        setIsSocketConnected,
        isAdminClient,
        selectedUsersIds,
        setSelectedUsersIds,
        connectionsMeta,
        setTimeoutUnqueued,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
