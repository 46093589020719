import React, { useState } from "react";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";

import {
  Button,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Table,
  TableHead,
  Paper,
  Tooltip,
	Typography,
} from "@material-ui/core";
import { ReplayOutlined } from "@material-ui/icons";

import MainHeader from "../../components/MainHeader";

import api from "../../services/api";
import ConfirmationModal from "../../components/ConfirmationModal";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";

import useStyles from "./styles";

const confirmationModalInitialState = {
	action: "",
	title: "",
	message: "",
	whatsAppId: "",
	open: false,
};

const ConnectionsInatives = ({metas, whatsApps, setMetas}) => {
  const classes = useStyles();

	const [confirmModalInfo, setConfirmModalInfo] = useState(
    confirmationModalInitialState
  );
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);


	const handleOpenConfirmationModal = (action, whatsAppId) => {
		setConfirmModalInfo({
			action,
			title: 'Restaurar conexão',
			message: 'Deseja restaurar a conexão?',
			whatsAppId,
			open: true,
		});

    setConfirmModalOpen(true);
  };

	const handleSubmitConfirmationModal = async () => {
    if (confirmModalInfo.action === "restore") {
      try {
        await api.post(`/status/whatsapp/${confirmModalInfo.whatsAppId}`);
				toast.success("Conexão restaurada com sucesso!");
      } catch (err) {
        toastError(err);
      }
    }

    if (confirmModalInfo.action === "restoreMeta") {
      try {
        await api.post(`/status/meta/${confirmModalInfo.whatsAppId}`);
				const response = await api.get(`/meta`);
				setMetas(response.data);
				toast.success("Conexão restaurada com sucesso!");
      } catch (err) {
        toastError(err);
      }
    }

    setConfirmModalInfo(confirmationModalInitialState);
  };

  const formatNumber = (number) => {
    if (!number) return "";
    if (number.length === 13) {
      return number.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})/, "+$1 ($2) $3-$4");
    }
    if (number.length === 12) {
      return number.replace(/^(\d{2})(\d{2})(\d{4})(\d{4})/, "+$1 ($2) $3-$4");
    }
    return number;
  };

  return (
    <>
			<ConfirmationModal
				title={confirmModalInfo.title}
				open={confirmModalOpen}
				onClose={setConfirmModalOpen}
				onConfirm={handleSubmitConfirmationModal}
				>
				{confirmModalInfo.message}
			</ConfirmationModal>
			<MainHeader>
				<Typography className={classes.titleMain} variant="h5" color="primary" gutterBottom>
					Conexões Inativas
				</Typography>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.name")}
              </TableCell>
              <TableCell align="center">Número</TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.session")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.lastUpdate")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.default")}
              </TableCell>
              <TableCell align="center">Reenvios</TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
						{whatsApps?.length > 0 &&
							whatsApps.map((whatsApp) => (
								whatsApp.status === "INATIVE" && (
									<TableRow key={whatsApp.id}>
										<TableCell align="center">{whatsApp.id}</TableCell>
										<TableCell
											align="center"
											className={classes.cellElelement}
										>
											{whatsApp.name}
										</TableCell>
										<TableCell
											align="center"
											className={classes.cellElelement}
										>
											{formatNumber(whatsApp.number)}
										</TableCell>
										<TableCell align="center">
											<Button
												size="small"
												variant="outlined"
												color="secondary"
												// onClick={() => {
												// 	handleOpenConfirmationModal("disconnect", whatsApp.id);
												// }}
												style={{ cursor: "not-allowed" }}
											>
												Inativa
											</Button>
										</TableCell>
										<TableCell
											align="center"
											className={classes.cellElelement}
										>
											{format(parseISO(whatsApp.updatedAt), "dd/MM/yy HH:mm")}
										</TableCell>
										<TableCell align="center">{/* is default */}</TableCell>
										<TableCell align="center">{/* reenvios */}</TableCell>
										<TableCell
											align="center"
											className={classes.cellElelement}
										>
											<>
												<Tooltip title="Restaurar Conexão">
													<IconButton
														size="small"
														onClick={(e) => {
															handleOpenConfirmationModal(
																"restore",
																whatsApp.id
															);
														}}
													>
														<ReplayOutlined />
													</IconButton>
												</Tooltip>
											</>
										</TableCell>
									</TableRow>
							)))}
						{metas?.length > 0 &&
							metas.map((meta) => (
								meta.status === "INATIVE" && (
									<TableRow key={meta.id}>
										<TableCell align="center">{meta.id}</TableCell>
										<TableCell align="center">{meta.name}</TableCell>
										{/* <TableCell align="center">{renderStatusToolTips(meta)}</TableCell> */}
										<TableCell align="center">
											{meta.name.includes("IG")
												? "Página do Instagram"
												: "Página do Facebook"}
										</TableCell>
										<TableCell align="center">
											<Button
												size="small"
												variant="outlined"
												color="secondary"
												// onClick={() => {
												// 	handleOpenConfirmationModal("disconnect", whatsApp.id);
												// }}
												style={{ cursor: "not-allowed" }}
											>
												Inativa
											</Button>
										</TableCell>
										<TableCell align="center">
											{format(parseISO(meta.updatedAt), "dd/MM/yy HH:mm")}
										</TableCell>

										<TableCell align="center">{/* is default */}</TableCell>

										<TableCell align="center">{/* reenvios */}</TableCell>

										<TableCell align="center">
												<>
												<Tooltip title="Restaurar Conexão">
												<IconButton
														size="small"
														onClick={(e) => {
															handleOpenConfirmationModal(
																"restoreMeta",
																meta.id
															);
														}}
													>
														<ReplayOutlined />
													</IconButton>
												</Tooltip>
												</>

										</TableCell>
									</TableRow>
							)))}
          </TableBody>
        </Table>
      </Paper>
   </>
  );
};

export default ConnectionsInatives;
