import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    gap: "16px",
    paddingBottom: "8px",
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		}
  },

  filterContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "8px",
    padding: "8px 0 0 8px",
		[theme.breakpoints.down('xs')]: {
			flexWrap: "nowrap",
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		}
  },

  filtersTitle: {
    width: "100%",
		[theme.breakpoints.down('xs')]: {
			width: "auto",
		}
  },

  textInput: {
    marginTop: "4px",
    "& .MuiInputBase-input": {
      fontSize: "0.8em",
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "0.8em",
    },
    "& .MuiOutlinedInput-root": {
      height: "34px",
      width: "200px",
    },
  },

  divider: {
    backgroundColor: theme.palette.primary.main,
    height: "unset",
    width: "8px"
  },

  filterSecondPart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
		[theme.breakpoints.down('xs')]: {
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
		}
  },

  filterDate: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
  },

  filterButtons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
		[theme.breakpoints.down('xs')]: {
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
		}
  },

  searchBtn: {
    marginRight: "30px",
    height: "40px",
		[theme.breakpoints.down('xs')]: {
			marginRight: "0px",
		}
  },

  clearFiltersBtn: {
    fontSize: "0.8em",
    marginRight: "30px",
    height: "40px",
    lineHeight: "1em",
		[theme.breakpoints.down('xs')]: {
			marginRight: "0px",
		}
  },
}));
