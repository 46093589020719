import toastError from "../errors/toastError";
import api from "../services/api";

const getOpenTicket = async (metaId, contactId, whatsId, userId) => {
  if (whatsId || metaId) {
    const wId = whatsId || null
    const mId = metaId || null
    try {
      const { data: ticket } = await api.get(`/tickets/contact/${contactId}/whats/${wId}/user/${userId}/meta/${mId}`);
      return ticket;    
    } catch (err) {
      toastError(err);    
    }
  }
};

export default getOpenTicket;
