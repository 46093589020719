import React, { createContext, useContext } from "react";

import useCustomers from "../../hooks/useCustomers";
import useNotices from "../../hooks/useNotices";

const AdminContext = createContext();

/**
 * @typedef {object} CustomerContextReturn
 * @property {array<object>} customers
 * @property {boolean} customerLoading
 * @property {boolean} hasMoreCustomers
 * @property {function} setCustomersPageNumber
 * @property {function} createCustomer
 * @property {function} updateCustomer
 * @property {function} deleteCustomer
 *
 * @returns {CustomerContextReturn}
 */

export function useAdminContext() {
  return useContext(AdminContext);
}

export function AdminProvider({ children }) {
  const customersHook = useCustomers();
  const noticesHook = useNotices();

  return (
    <AdminContext.Provider value={{ ...customersHook, ...noticesHook }}>
      {children}
    </AdminContext.Provider>
  )
}
