/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

import { Button, CircularProgress, IconButton, Tooltip, useMediaQuery, useTheme } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Facebook, Instagram, MoreVertOutlined, WhatsApp } from "@material-ui/icons";
import clsx from "clsx";
import { format, isSameDay, parseISO } from "date-fns";
import { useHistory, useParams } from "react-router-dom";

import WhatsAppBusinessIcon from "../../assets/WhatsAppBusinessIcon.png";
import pinIcon from "../../assets/pinIcon.png";
import { useAuthContext } from '../../context/Auth/AuthContext';
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import MarkdownWrapper from "../MarkdownWrapper";
import QueueSelectModal from "../QueueSelectModal";
import TicketContextMenu from "../TicketContextMenu";

const useStyles = makeStyles((theme) => ({
  ticketUserName: {
    color: "rgb(104, 121, 146)",
    position: "absolute",
    bottom: "18px",
    right: -20,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "25%",
    textAlign: "end",
    paddingRight: "28px",
    fontSize: "0.8em",
    // fontWeight: "900",
  },

  ticket: {
    backgroundColor: "#fff",
    width: "98%",
    height: "80px",
    margin: "auto",
    marginTop: "5px",
    position: "relative",
    // paddingBottom: "20px",
    borderRadius: "4px",
    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.3)",
    overflow: "hidden",
  },

  pendingTicket: {
    cursor: "unset",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  contactNameWrapper: {
    marginLeft: 10,
    display: "flex",
    justifyContent: "space-between",
  },

  lastMessageTime: {
    justifySelf: "flex-end",
    marginRight: -4,
    fontSize: "0.8em",
  },

  closedBadge: {
    alignSelf: "center",
    justifySelf: "flex-end",
    marginRight: 32,
    marginLeft: "auto",
  },

  contactLastMessage: {
    paddingRight: 20,
    paddingBottom: 30,
    width: "80%",
    fontSize: "0.9em",
  },

  newMessagesCount: {
    position: "absolute",
    marginLeft: -10,
    marginTop: -13,
    fontSize: "1em",
    padding: 0,
    // alignSelf: "center",
    // marginRight: 8,
    // marginLeft: "auto",
  },

  badgeStyle: {
    color: "white",
    backgroundColor: green[500],
    fontSize: "0.8em",
    padding: 10,
    margin: 0,
    minWidth: "0px",
    width: "15px",
    height: "15px",

    "&:before": {
      content: '""',
      position: "absolute",
      width: 15,
      height: 15,
      padding: 10,
      top: "0",
      left: "0",
      // transform: "translate(-50%, -50%)",
      borderRadius: "50%",
      backgroundColor: green[500],
      opacity: 0,
      animation: "$rippleEffect 1s linear infinite",
    },
  },

  "@keyframes rippleEffect": {
    "0%": {
      opacity: 0.5,
    },
    "100%": {
      opacity: 0,
      transform: "scale(2)",
    },
  },

  markedAsReadStyle: {
    alignSelf: "center",
    backgroundColor: green[500],
    color: green[500],
    marginTop: -63,
    marginRight: -42,
  },

  acceptButton: {
    position: "absolute",
    left: "50%",
    // width: "100%",
    // height: "100%",
    zIndex: 1,
    // opacity: 0,
  },

  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
  },

  userTag: {
    position: "absolute",
    marginRight: 5,
    right: 5,
    bottom: 5,
    background: "#2576D2",
    color: "#ffffff",
    border: "1px solid #CCC",
    padding: 1,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 10,
    fontSize: "0.9em",
  },

  userTagFacebook: {
    position: "absolute",
    marginRight: 5,
    right: 5,
    bottom: 5,
    background: "#2576D2",
    color: "#ffffff",
    border: "1px solid #CCC",
    padding: 1,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 10,
    fontSize: "0.9em",
  },

  userTagWhatsapp: {
    position: "absolute",
    marginRight: 5,
    right: 5,
    bottom: 5,
    background: "#25D366",
    color: "#ffffff",
    border: "1px solid #CCC",
    padding: 1,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 10,
    fontSize: "0.9em",
  },

  userTagInstagram: {
    position: "absolute",
    marginRight: 5,
    right: 5,
    bottom: 5,
    background: "#E1306C",
    color: "#ffffff",
    border: "1px solid #CCC",
    padding: 1,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 10,
    fontSize: "0.9em",
  },

  userTagsContainer: {
    position: "absolute",
    display: "flex",
    gap: "2px",
    bottom: 12,
    left: 85,
    width: "60%",
    heigth: "20px",
    alignItems: "center",
    overflow: "hidden",
  },

  userTags: {
    color: "#ffffff",
    padding: 0.3,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 5,
    fontSize: "0.7em",
    fontWeight: "900",
    textShadow: "0 0 2px rgba(0, 0, 0, 0.8)",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "100px",
  },
}));

const TicketListItem = (props) => {
  const {
    ticket,
    unreadsList,
    updateUnreadsList,
    column,
    setChangeIsFixed,
    innerRef,
    deleteTicketFromList,
		userCanSeePotential = () => {},
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { user, users, timeoutUnqueued } = useAuthContext();
  const [userTicketName, setUserTicketName] = useState("");
  const [contactTags, setContactTags] = useState([]);
  const [onBot, setOnBot] = useState(false);
  const [valid, setValid] = useState(true);
  const [ticketTimeout, setTicketTimeout] = useState(null);

  const [queueSelectModalOpen, setQueueSelectModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [markedAsUnread, setMarkedAsUnread] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const handleMarkAsRead = async () => {
    if (markedAsUnread === false) return;
    try {
      await api.patch("/tickets/unread", {
        markedAsUnread: false,
        ticketId: ticket.id,
      });

      setMarkedAsUnread(false);
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    setMarkedAsUnread(ticket.markedAsUnread);

    if (users.users && ticket) {
      const userName = users.users.find(
        (user) => Number(user.id) === Number(ticket.userId)
      )?.name;
      setUserTicketName(userName);
      const contactTags = ticket.contact.tags;
      setContactTags(contactTags);
    }
  }, [ticket, users]);

  useEffect(() => {
    clearTimeout(ticketTimeout);
    const timer = timeoutUnqueued;
    const newDate = new Date();
    const actualDate = newDate.getTime() / 1000; // now date in seconds
    const ticketCreatedAt = Date.parse(ticket.createdAt) / 1000; // ticket create in seconds
    const ticketAge = actualDate - ticketCreatedAt; // ticket age in seconds
    const remainingTime = timer - ticketAge; // remaining time in seconds

    if (
      !ticket.queue &&
      ticketAge < timer &&
      Number(timeoutUnqueued) !== 0 &&
      ticket.status === "pending" &&
      ticket.contact.userId !== user.id
    ) {
      setOnBot(true);

      const ticketTO = setTimeout(() => {
        setOnBot(false);
        setTicketTimeout(null);
      }, remainingTime * 1000);

      setTicketTimeout(ticketTO);
    } else if (
      !ticket.queue &&
      Number(timeoutUnqueued) === 0 &&
      user.profile !== "admin" &&
      ticket.contact.userId !== user.id &&
			!userCanSeePotential({
				ticket
			})
    ) {
      setOnBot(true);
    }
    else if (
      !ticket.queue &&
      Number(timeoutUnqueued) > 0 &&
      user.profile !== "admin" &&
      ticket.contact.userId !== user.id &&
			!userCanSeePotential({
				ticket
			})
    ) {
      setOnBot(true);
    }
		else {
      setOnBot(false);
    }
  }, [ticket, timeoutUnqueued, user, userCanSeePotential]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleAcepptTicket = async (id) => {
    deleteTicketFromList(id);

    setIsLoading(true);
    if (Boolean(unreadsList)) {
      const newList = unreadsList.filter((item) => item.id !== id);
      updateUnreadsList(newList);
    }
    try {

      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
      });


      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    } catch (err) {
      toastError(err);
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
    history.push(`/tickets/${id}`);
  };

  const handleTicketQueue = async () => {
    setQueueSelectModalOpen(true);
    setIsLoading(true);
  };

  const handleSelectTicket = async (id) => {
    if (Boolean(unreadsList)) {
      const newList = unreadsList.filter((item) => item.id !== id);
      updateUnreadsList(newList);
    }

    if (!Boolean(anchorEl)) {
      await handleMarkAsRead();
      history.push(`/tickets/${id}`);
    }
  };

  const handleOpenContextMenu = (e) => {
    e.preventDefault();
    if (Boolean(unreadsList)) return;
    setAnchorEl(e.currentTarget);
  };

  const handleCloseContextMenu = () => setAnchorEl(null);

  const handleMarkAsUnread = (bool) => setMarkedAsUnread(bool);

  return (
    <>
      {onBot ? null : (
        <div ref={innerRef}>
          <QueueSelectModal
            modalOpen={queueSelectModalOpen}
            onClose={() => setQueueSelectModalOpen(false)}
            ticket={ticket}
            unreadsList={unreadsList}
            updateUnreadsList={updateUnreadsList}
            deleteTicketFromList={deleteTicketFromList}
          />

					<div
						style={{display: 'flex', gap: "5px"}}
					>
						<ListItem
							dense
							button
							onClick={(e) => {
								if (ticket.status === "pending") return;
								handleSelectTicket(ticket.id);
							}}
							onContextMenu={(e) => {
								handleOpenContextMenu(e);
							}}
							selected={ticketId && +ticketId === ticket.id}
							className={clsx(classes.ticket, {
								[classes.pendingTicket]: ticket.status === "pending",
							})}
						>
							<TicketContextMenu
								ticket={ticket}
								anchorEl={anchorEl}
								onClose={handleCloseContextMenu}
								onMarkAsUnread={handleMarkAsUnread}
								isMarkedAsUnread={markedAsUnread}
								setChangeIsFixed={setChangeIsFixed}
							/>

							<Tooltip
								arrow
								placement="right"
								title={ticket.queue?.name || "Sem Setor"}
							>
								<span
									style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
									className={classes.ticketQueueColor}
								></span>
							</Tooltip>
							<ListItemAvatar>
								<div className={classes.avatarContainer}>
									<Avatar
										variant="rounded"
										style={{ width: 60, height: 60, marginTop: 10, zIndex: 0 }}
										onError={() => setValid(false)}
										src={valid ? ticket?.contact?.profilePicUrl : ""}
									/>
									{ticket?.whatsapp &&
										!ticket?.whatsapp?.isOficial &&
										!["facebook", "instagram"].includes(
											ticket?.contact?.channel
									) && (
											<WhatsApp
												style={{
													fill: "#25D366",
													fontSize: 18,
													marginTop: -10,
													zIndex: 1,
													backgroundColor: "rgba(255,255,255,1)",
													borderRadius: "50%",
													filter: "drop-shadow(0px 0px 5px #00000070)",
												}}
											/>
										)}
									{ticket?.whatsapp &&
										ticket?.whatsapp?.isOficial &&
										!["facebook", "instagram"].includes(
											ticket?.contact?.channel
										) && (
											<img
												src={WhatsAppBusinessIcon}
												style={{
													width: "20px",
													height: "20px",
													marginTop: "-10px",
													zIndex: 1,
													backgroundColor: "rgba(255,255,255,1)",
													borderRadius: "50%",
													filter: "drop-shadow(0px 0px 5px #00000070)",
												}}
												alt=""
											/>
										)}
									{ticket?.contact?.channel === "instagram" && (
										<Instagram
											style={{
												fill: "#E1706C",
												fontSize: 18,
												marginTop: -10,
												zIndex: 1,
												backgroundColor: "rgba(255,255,255,1)",
												borderRadius: "5px",
												filter: "drop-shadow(0px 0px 5px #00000070)",
											}}
										/>
									)}
									{ticket?.contact?.channel === "facebook" && (
										<Facebook
											style={{
												fill: "#4267B2",
												fontSize: 18,
												marginTop: -10,
												zIndex: 1,
												backgroundColor: "rgba(255,255,255,1)",
												borderRadius: "3px",
												filter: "drop-shadow(0px 0px 5px #00000070)",
											}}
										/>
									)}
									{markedAsUnread && (
										<Badge
											overlap="rectangular"
											badgeContent="*"
											classes={{ badge: classes.markedAsReadStyle }}
										/>
									)}
								</div>
							</ListItemAvatar>
							<ListItemText
								disableTypography
								primary={
									<span className={classes.contactNameWrapper}>
										<Typography
											noWrap
											component="span"
											variant="body2"
											color="textPrimary"
											style={{ display: "flex", alignItems: "center", gap: 5 }}
										>
											{ticket.isFixed && (
												<img
													src={pinIcon}
													alt="pinIcon"
													style={{ width: 20, height: 20 }}
												/>
											)}
											<Typography
												component="span"
												style={{
													color: "grey",
													fontSize: "0.8em",
													fontStyle: "italic",
												}}
											>{`#${ticket.id} - `}</Typography>
											{ticket.contact?.name}
										</Typography>
										{ticket.status === "closed" && (
											<Badge
												overlap="rectangular"
												className={classes.closedBadge}
												badgeContent={"closed"}
												color="primary"
											/>
										)}
										{ticket.lastMessage && (
											<Typography
												className={classes.lastMessageTime}
												component="span"
												variant="body2"
												color="textSecondary"
											>
												{isSameDay(
													parseISO(
														column === "lastMessageDate"
															? ticket.lastMessageDate
															: ticket.createdAt
													),
													new Date()
												) ? (
													<>
														{format(
															parseISO(
																column === "lastMessageDate"
																	? ticket.lastMessageDate
																	: ticket.createdAt
															),
															"HH:mm"
														)}
													</>
												) : (
													<>
														{format(
															parseISO(
																column === "lastMessageDate"
																	? ticket.lastMessageDate
																	: ticket.createdAt
															),
															"dd/MM/yyyy"
														)}
													</>
												)}
											</Typography>
										)}
										{(ticket?.contact?.channel === "whatsapp" ||
											ticket?.contact?.channel === "whatsapp_official" ||
											!ticket?.contact?.channel) && (
												<div
													className={classes.userTagWhatsapp}
													title={i18n.t("ticketsList.connectionTitle")}
												>
													{ticket?.whatsapp?.name}
												</div>
											)}
										{ticket?.contact?.channel === "facebook" && (
											<div
												className={classes.userTagFacebook}
												title={i18n.t("ticketsList.connectionTitle")}
											>
												{ticket?.meta?.name}
											</div>
										)}
										{ticket?.contact?.channel === "instagram" && (
											<div
												className={classes.userTagInstagram}
												title={i18n.t("ticketsList.connectionTitle")}
											>
												{ticket?.meta?.name}
											</div>
										)}
									</span>
								}
								secondary={
									<Tooltip title={ticket.lastMessage || ""}>
										<span className={classes.contactNameWrapper}>
											<Typography
												className={classes.contactLastMessage}
												noWrap
												component="span"
												variant="body2"
												color="textSecondary"
											>
												{ticket.lastMessage ? (
													<MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
												) : (
													<br />
												)}
											</Typography>

											<Badge
												overlap="rectangular"
												className={classes.newMessagesCount}
												badgeContent={ticket.unreadMessages}
												classes={{
													badge: classes.badgeStyle,
												}}
											/>
										</span>
									</Tooltip>
								}
							/>
							{ticket.status === "pending" && (
								<Button
									color="primary"
									variant="contained"
									className={classes.acceptButton}
									size="small"
									onClick={() => {
										ticket.queueId
											? handleAcepptTicket(ticket.id)
											: handleTicketQueue();
									}}
									loading={isLoading}
									disabled={isLoading}
								>
									{isLoading ? (
										<CircularProgress
											size={15}
											style={{
												color: "white",
											}}
										/>
									) : (
										i18n.t("ticketsList.buttons.accept")
									)}
								</Button>
							)}
							{user.profile === "admin" && onBot ? (
								<p className={classes.ticketUserName}>Bot</p>
							) : (
								<Tooltip title={userTicketName || ""}>
									<p className={classes.ticketUserName}>{userTicketName}</p>
								</Tooltip>
							)}
							<div className={classes.userTagsContainer}>
								{contactTags?.length > 0 &&
									contactTags.map((tag) => (
										<Tooltip title={tag?.name || ""} key={tag.id}>
											<Typography
												// pop up com nome da tag
												key={tag.id}
												className={classes.userTags}
												style={{
													background: tag.color,
													border: `1px solid ${tag.color}`,
												}}
											// title={i18n.t("ticketsList.connectionTitle")}
											>
												{tag?.name}
											</Typography>
										</Tooltip>
									))}
							</div>
						</ListItem>

						{isMobile && (
							<IconButton
								style={{padding: "0px"}}
								onClick={(e) => handleOpenContextMenu(e)}
							>
								<MoreVertOutlined />
							</IconButton>
						)}

					</div>
        </div>
      )}
      {/* <Divider variant="inset" component="li" /> */}
    </>
  );
};

export default TicketListItem;
