import React, { useEffect, useState } from "react";

import { useAuthContext } from '../../context/Auth/AuthContext';
import { useCustomerNoticesContext } from "../../context/CustomerNotices";
import Header from "./Header";
import Popup from "./Popup";

function CustomerNotice() {
  const { customerNotices, dispatchCustomerNotices, markNoticeAsRead } = useCustomerNoticesContext();
  const { isAdminClient } = useAuthContext();
  const [isNoticeOpen, setIsNoticeOpen] = useState(false);
  const isPopup = customerNotices[0]?.type === "popup";

  const handleCloseNotice = () => {
    setIsNoticeOpen(false);
    if (!isAdminClient) markNoticeAsRead(customerNotices[0].id);
    dispatchCustomerNotices({ type: "DELETE_DATA", payload: customerNotices[0].id });
  };

  useEffect(() => {
    if (customerNotices.length) {
      setTimeout(() => setIsNoticeOpen(true), 500);
    }
  }, [customerNotices]);

  if (!isNoticeOpen) return <></>;

  return isPopup
    ? <Popup notice={customerNotices[0]} handleClose={handleCloseNotice} />
    : <Header notice={customerNotices[0]} handleClose={handleCloseNotice} />;
}

export default CustomerNotice;
