import React from "react";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import uuid from 'react-uuid';

import { useAuthContext } from "../../context/Auth/AuthContext";
import { useSettingsContext } from '../../context/Settings';
import toastError from "../../errors/toastError";
import useMixpanel from "../../hooks/useMixpanel";
import api from "../../services/api";

const TicketContextMenu = ({
  ticket,
  anchorEl,
  onClose,
  onMarkAsUnread,
  isMarkedAsUnread,
	setChangeIsFixed
}) => {
  const history = useHistory();
  const mixpanel = useMixpanel();
  const { user } = useAuthContext();
  const { getSettingValue } = useSettingsContext()

  const isStatusPending = ticket.status === "pending";

  const getTicketPreviewPermission = (profile) => {
    const ticketPreviewPermission = getSettingValue("ticketPreviewPermission");

    switch (ticketPreviewPermission) {
      case "user":
        return true;
      case "supervisor":
        return profile !== "user";
      default:
        return profile === "admin";
    }
  }

  const handleClose = () => {
    onClose();
  };

  const handleMarkAsUnread = async () => {
    try {
      await api.patch("/tickets/unread", {
        markedAsUnread: !isMarkedAsUnread,
        ticketId: ticket.id,
      });

      handleClose();
      onMarkAsUnread(!isMarkedAsUnread);
    } catch (err) {
      toastError(err);
      handleClose();
    }
  };

  const handleFinishTicket = async () => {
    // fixing here
    try {
      await api.put(`/tickets/${ticket.id}`, {
        status: "closed",
        sendSurvey: false,
				actionNameUser: user?.name
      });

			mixpanel.track('Ticket Change', {
				"Action": "End Ticket",
				"Origin": "Right button"
			});

      handleClose();
    } catch (err) {
      toastError(err);
      handleClose();
    }
  };

  const handleOpenTicket = () => {
    history.push(`/tickets/${ticket.id}`);
    api.post(`/track-ticket-preview`, {
      ticketId: ticket.id,
      contactId: ticket.contactId
    })
    handleClose();
  };

	const handleFixTicket = async () => {
		try {
			await api.put(`/tickets/${ticket.id}`, {
				...ticket,
					isFixed: ticket.isFixed ? null : true,
			});

			setChangeIsFixed(uuid());

			handleClose();
		} catch (err) {
			toastError(err);
			handleClose();
		}
	}

  return (
		<Menu
			anchorEl={anchorEl}
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: "center",
				horizontal: "right",
			}}
			keepMounted
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			open={Boolean(anchorEl)}
			onClose={handleClose}
		>
			<MenuItem onClick={handleMarkAsUnread}>
				{isMarkedAsUnread ? "Marcar como lido" : "Marcar como não lido"}
			</MenuItem>
			{(isStatusPending && getTicketPreviewPermission(user.profile)) && (
				<MenuItem onClick={handleOpenTicket}>Visualizar Ticket</MenuItem>
			)}
			<MenuItem onClick={handleFinishTicket}>Finalizar</MenuItem>
			{ticket.isFixed && ticket.status === 'open'&& (
				<MenuItem onClick={handleFixTicket}>Desafixar no Topo</MenuItem>
			)}
			{!ticket.isFixed && ticket.status === 'open'&& (
				<MenuItem onClick={handleFixTicket}>Fixar no Topo</MenuItem>
			)}
		</Menu>
  );
};

export default TicketContextMenu;
