import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import ModalImage from "react-modal-image";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  messageMedia: {
    objectFit: "cover",
    minWidth: 250,
    minHeight: 200,
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
}));

const ModalImageCors = ({ imageUrl, channel }) => {
  const classes = useStyles();
  const [fetching, setFetching] = useState();
  const [blobUrl, setBlobUrl] = useState("");

  useEffect(() => {
    setFetching(true);
    if (!imageUrl) return;
    const fetchImage = async () => {
      try {
        const { data, headers } = await api.get(imageUrl, {
          responseType: "blob",
        });
        const url = window.URL.createObjectURL(
          new Blob([data], { type: headers["content-type"] })
        );
        setBlobUrl(url);
      } catch (e) {
        console.log(e);
      }
    };
    if (channel) {
      setBlobUrl(imageUrl.split("/public/")[1]);
    } else {
      fetchImage();
    }
    setFetching(false);
  }, [imageUrl, channel]);

  return (
    <ModalImage
      className={classes.messageMedia}
      smallSrcSet={fetching ? imageUrl : blobUrl}
      medium={fetching ? imageUrl : blobUrl}
      large={fetching ? imageUrl : blobUrl}
      alt="image"
    />
  );
};

export default ModalImageCors;
