import React from "react";

import {
  Box,
  Paper,
  Typography,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
} from '@material-ui/core'
import { Business, Close } from '@material-ui/icons';

import { useStyles } from './styles';
import { useAuthContext } from '../../../context/Auth/AuthContext';

import ReactQuill from 'react-quill';

/**
 * @typedef {object} NoticeInfos
 * @property {string} message
 * @property {string} youtubeVideoId
 *
 * @typedef {object} PopupParams
 * @property {NoticeInfos} notice
 * @property {function} handleClose
 *
 * @param {PopupParams} params
 * @returns
 */

const youtubeEmbedPattern = /^(https?:\/\/)?(www\.)?youtube\.com\/embed\/[\w-]{11}$/

function Popup({ notice, handleClose }) {
  const classes = useStyles();
  const { isAdminClient } = useAuthContext();
  const isYoutubeVideo = youtubeEmbedPattern.test(notice.url);

  const handleClick = () => {
    if (notice.url && !isYoutubeVideo) {
      return window.open(notice.url);
    }
    handleClose();
  }

  return (
    <Box className={classes.box}>
      {isAdminClient && (
        <List className={classes.customerList}>
          {notice.customers.map(customer => (
            <ListItem key={customer.id}>
              <ListItemAvatar>
                <Avatar className={classes.customerAvatar}>
                  <Business color="primary" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={customer?.name}
                secondary={customer.url}
                className={classes.customerText}
              />
            </ListItem>
          ))}
        </List>
      )}
      <Paper 
				className={classes.paper}
			>
				<IconButton
					size="small"
					className={classes.closeIcon}
					onClick={handleClose}
				>
					<Close />
				</IconButton>

				<Typography variant="h6" className={classes.typography}>
          {notice?.title || "Aviso do time DeskRio"}
        </Typography>

				<ReactQuill 
					onChange={(e) => console.log(e)}
					value={`${notice?.message || ""}`} 
					readOnly={true}
					modules={{
						toolbar: false
					}}
					className={classes.quill}
				/>

				<Button
					variant="contained"
					color="primary"
					className={classes.button}
					onClick={handleClick}
				>
					{(notice?.url && !isYoutubeVideo) ? "Link" : "Fechar"}
				</Button>
      </Paper>
    </Box>
  );
}

export default Popup;
