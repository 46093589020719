import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(8, 8, 3),
    flexDirection: "column"
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "552px",
  },
  navLinkContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "30%",
    marginBottom: "20px",
  },
  navLink: {
    color: "#808080",
    textAlign: "center",
    textDecoration: "none"
  },
  isRouteActive: {
    color: "#42722c"
  },
}));
