import React, { createContext, useContext } from "react";

import useCustomerNotices from "../../hooks/useCustomerNotices";

const CustomerNoticesContext = createContext();

export function useCustomerNoticesContext() {
  return useContext(CustomerNoticesContext);
}

export function CustomerNoticesProvider({ children }) {
  const customerNoticeHook = useCustomerNotices();

  return (
    <CustomerNoticesContext.Provider value={{ ...customerNoticeHook }}>
      {children}
    </CustomerNoticesContext.Provider>
  )
}
