import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { getBackendUrlV1 } from "../config";

const cache = setupCache({
  maxAge: 5 * 60 * 1000,
  exclude: {
    methods: ["post", "put", "patch", "delete"],
    paths: ["/tickets", "/contacts", "/messages"],
  },
});

const api = axios.create({
  baseURL: getBackendUrlV1(),
  withCredentials: true,
  // adapter: cache.adapter,
});

export default api;
