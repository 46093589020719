import React from "react";

import { Redirect, Route as RouterRoute } from "react-router-dom";

import BackdropLoading from "../components/BackdropLoading";
import { useAuthContext } from '../context/Auth/AuthContext';
import { useSettingsContext } from '../context/Settings';

function Route(params) {
  const {
    component: Component,
    isPrivate = false,
    ...rest
  } = params;

  const { isAuth, loading } = useAuthContext();
  const { settingLoading } = useSettingsContext();

  const isLoading = loading || settingLoading;

  if (!isAuth && isPrivate) {
    return (
      <>
        {isLoading && <BackdropLoading />}
        <Redirect to={{ pathname: "/login", state: { from: rest.location } }} />
      </>
    );
  }

  if (isAuth && !isPrivate) {
    return (
      <>
        {isLoading && <BackdropLoading />}
        <Redirect to={{ pathname: "/", state: { from: rest.location } }} />
      </>
    );
  }

  return (
    <>
      {isLoading && <BackdropLoading />}
      <RouterRoute {...rest} component={Component} />
    </>
  );
};

export default Route;
