import React, { useEffect, useRef, useState } from "react";

import {
    IconButton,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@material-ui/core";
import { DeleteOutline, Visibility } from "@material-ui/icons";
import Alert from '@material-ui/lab/Alert/Alert';

import ConfirmationModal from "../../components/ConfirmationModal";
import { useAdminContext } from "../../context/AdminContext";
import { useCustomerNoticesContext } from '../../context/CustomerNotices';
import { i18n } from '../../translate/i18n';
import TableRowSkeleton from "../TableRowSkeleton";
import { useStyles } from "./styles";

function NoticeTable() {
  const lastNotice = useRef(null);
  const classes = useStyles();
  const { dispatchCustomerNotices } = useCustomerNoticesContext();
  const {
    deleteNotice,
    noticeLoading,
    notices,
    hasMoreNotices,
    setNoticesPageNumber,
    updateNotice
  } = useAdminContext();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deleteableNotice, setDeleteableNotice] = useState(null);

  const { status, action } = noticeLoading;
  const isLoading = status === "loading";
  const readAction = action === "read";
  const createAction = action === "create";

  const handleOpenConfirmModal = (notice) => {
    setConfirmModalOpen(true);
    setDeleteableNotice(notice);
  };

  const handleCloseConfirmModal = () => {
    setConfirmModalOpen(false);
    setDeleteableNotice(null);
  }

  const handleDelete = () => {
    return deleteNotice(deleteableNotice.id);
  }

  const handlePreview = (notice) => {
    dispatchCustomerNotices({ type: "RESET_DATA" });
    dispatchCustomerNotices({
      type: "UPDATE_DATA",
      payload: notice
    });
  }

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setNoticesPageNumber(prevPage => prevPage + 1);
      }
    });
    if (lastNotice.current) {
      intersectionObserver.observe(lastNotice.current);
    }
    return () => intersectionObserver.disconnect();
  }, [notices, setNoticesPageNumber]);

  return (
    <>
      <ConfirmationModal
        title={i18n.t("noticeModal.confirmationModal.deleteTitle")}
        open={confirmModalOpen}
        onClose={handleCloseConfirmModal}
        onConfirm={handleDelete}
      >
        {i18n.t("noticeModal.confirmationModal.deleteMessage")}
      </ConfirmationModal>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                Título
              </TableCell>
              <TableCell align="center">
                Mensagem
              </TableCell>
              <TableCell align="center">
                Tipo
              </TableCell>
              <TableCell align="center">
                Severidade
              </TableCell>
              <TableCell align="center">
                URL
              </TableCell>
              <TableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {(isLoading && createAction) && <TableRowSkeleton columns={5} />}
              {notices.map((notice, index, arr) => (
                <TableRow
                  innerRef={index === arr.length - 1 && hasMoreNotices ? lastNotice : undefined}
                  key={notice.id}
                  className={classes.row}
                >
                  <TableCell>{notice.title}</TableCell>
                  <TableCell>{notice.message}</TableCell>
                  <TableCell align="center">{notice.type}</TableCell>
                  <TableCell align="center">
                    {notice.severity && (
                      <Alert style={{ width: "100%" }} severity={notice.severity}>
                        {i18n.t(`noticeModal.severity.${notice.severity}`)}
                      </Alert>
                    )}
                  </TableCell>
                  <TableCell align="center">{notice.url}</TableCell>
                  <TableCell align="center" width={152}>
                    <Switch
                      checked={notice.isActive}
                      onChange={() => updateNotice(notice)}
                      color="primary"
                      name="isNoticeActive"
                    />

                    <IconButton
                      size="small"
                      onClick={() => handleOpenConfirmModal(notice)}
                    >
                      <DeleteOutline />
                    </IconButton>

                    <Tooltip title="preview" placement="right">
                      <IconButton
                        size="small"
                        onClick={() => handlePreview(notice)}
                      >
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {(isLoading && readAction) && <TableRowSkeleton columns={5} />}
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default NoticeTable;
