import React, { useEffect, useState } from 'react';

import {
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';

import BotModal from '../../components/BotModal';
import ConfirmationModal from '../../components/ConfirmationModal';
import MainContainer from '../../components/MainContainer';
import MainHeader from '../../components/MainHeader';
import MainHeaderButtonsWrapper from '../../components/MainHeaderButtonsWrapper';
import OptionModal from '../../components/OptionModal';
import Title from '../../components/Title';
import { useAuthContext } from '../../context/Auth/AuthContext';
import api from '../../services/api';
import BotDrawer from './botDrawer';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 16,
    color: 'black',
  },
  bodyText: {
    fontSize: 14,
    color: '#171515',
    overflowWrap: 'anywhere',
  },
}));

const ChatBot = () => {
  const classes = useStyles();
  const [chatBots, setChatBots] = useState([]);
  const [botId, setBotId] = useState(null);
  const [optionId, setOptionId] = useState(null);
  const [optionParentId, setOptionParentId] = useState(null);
  const [optionModal, setOptionModal] = useState(false);
  const [botModal, setBotModal] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [openDeleteOptionConfirm, setOpenDeleteOptionConfirm] = useState(false);
  const { user } = useAuthContext();

  useEffect(() => {
    getChatBots();
  }, []);

  const getChatBots = () => {
    api.get('/chatbot').then(res => {
      setChatBots(res.data);
    });
  };

  const handleCloseOptionModal = () => {
    setOptionModal(false);
  };

  const handleCloseBotModal = () => {
    setBotModal(false);
  };

  const handleOpenBotModal = () => {
    setBotModal(true);
  };

  const handleCloseConfirmDeleteModal = () => {
    setOpenDeleteConfirm(false);
  };

  const handleOpenConfirmDeleteModal = id => {
    setBotId(id);
    setOpenDeleteConfirm(true);
  };

  const handleCloseOptionConfirmDeleteModal = () => {
    setOpenDeleteOptionConfirm(false);
  };

  const handleOpenOptionConfirmDeleteModal = id => {
    setOptionId(id);
    setOpenDeleteOptionConfirm(true);
  };

  const openOptionModal = (id, parentId = null) => {
    setOptionParentId(null);
    setBotId(null);
    setOptionModal(true);
    if (id) setBotId(id);
    if (parentId) setOptionParentId(parentId);
  };

  const deleteOption = async () => {
    await api.delete(`/option/${optionId}`);
    api.get('/chatbot').then(res => {
      setChatBots(res.data);
    });
  };

  const deleteBot = async () => {
    await api.delete(`/chatbot/${botId}`);
    const newList = chatBots.filter(bot => bot.id !== botId);
    setChatBots(newList);
  };

  return (
    <MainContainer>
      <MainHeader>
        <Title>Chat Bot</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              handleOpenBotModal();
            }}
          >
            Adicionar Bot
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <TableContainer component={Paper}>
        <Table aria-label='Chatbot table' size='medium'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Nome do Chatbot</TableCell>
              <TableCell align='left'>Mensagem de Boas-vindas</TableCell>
              <TableCell align='left'>
                Mensagem de Finalização de Atendimento
              </TableCell>
              <TableCell align='left'>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chatBots.map((bot, i) => (
              <TableRow key={i}>
                <TableCell align='left'>
                  <Typography className={classes.title} gutterBottom>
                    {bot?.name}
                  </Typography>
                </TableCell>
                <TableCell align='left'>
                  <Typography
                    variant='body2'
                    component='p'
                    className={classes.bodyText}
                  >
                    {bot.welcomeMessage}
                  </Typography>
                </TableCell>
                <TableCell align='left'>
                  <Typography
                    variant='body2'
                    component='p'
                    className={classes.bodyText}
                  >
                    {bot.endMessage}
                  </Typography>
                </TableCell>
                <TableCell align='left'>
                  {user.profile === 'admin' && (
                    <BotDrawer
                      bot={bot}
                      openOptionModal={openOptionModal}
                      deleteOption={handleOpenOptionConfirmDeleteModal}
                      deleteBot={handleOpenConfirmDeleteModal}
                      getChatBots={getChatBots}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Divider />
        <OptionModal
          modalOpen={optionModal}
          onClose={handleCloseOptionModal}
          chatBotId={botId}
          parentId={optionParentId}
          getChatBots={getChatBots}
        />
        <BotModal
          modalOpen={botModal}
          onClose={handleCloseBotModal}
          getChatBots={getChatBots}
        />
        <ConfirmationModal
          title={'Confirmar deleção de bot'}
          children={'Esta ação não pode ser desfeita'}
          open={openDeleteConfirm}
          onClose={handleCloseConfirmDeleteModal}
          onConfirm={deleteBot}
        />
        <ConfirmationModal
          title={'Confirmar deleção de node'}
          children={'Esta ação não pode ser desfeita'}
          open={openDeleteOptionConfirm}
          onClose={handleCloseOptionConfirmDeleteModal}
          onConfirm={deleteOption}
        />
      </TableContainer>
    </MainContainer>
  );
};

export default ChatBot;
