import React, { createContext, useContext } from "react";

import useWhatsApps from "../../hooks/useWhatsApps";

export const WhatsAppsContext = createContext();

export function useWhatsAppsContext() {
  return useContext(WhatsAppsContext);
}

export const WhatsAppsProvider = ({ children }) => {
	const { loading, whatsApps } = useWhatsApps();

	return (
		<WhatsAppsContext.Provider value={{ whatsApps, loading }}>
			{children}
		</WhatsAppsContext.Provider>
	);
};
