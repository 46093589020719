import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";

import {
	Avatar,
	Button,
	FormControlLabel,
	Grid,
	Hidden,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Menu,
	MenuItem,
	Switch,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import AppsTemplate from "@material-ui/icons/Apps";
import CalendarToday from "@material-ui/icons/CalendarToday";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/Clear";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import MicIcon from "@material-ui/icons/Mic";
import MoodIcon from "@material-ui/icons/Mood";
import MoreVert from "@material-ui/icons/MoreVert";
import SendIcon from "@material-ui/icons/Send";
import clsx from "clsx";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import MicRecorder from "mic-recorder-to-mp3";
import path from 'path';
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuthContext } from "../../context/Auth/AuthContext";
import { useReplyMessageContext } from "../../context/ReplyingMessage";
import { useSettingsContext } from '../../context/Settings';
import toastError from "../../errors/toastError";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import useMixpanel from "../../hooks/useMixpanel";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import QuickAnswerSelect from "../QuickAnswerSelect";
import QuickMentionSelect from "../QuickMentionSelect";
import TemplatesModal from "../TemplatesModal";
import TicketShareMenu from "../TicketShareMenu/index";
import RecordingTimer from "./RecordingTimer";
import { useStyles } from "./styles";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

function MessageInput({ ticket, handleOpenScheduleModal }) {
	const inputRef = useRef();
	const classes = useStyles();
	const { ticketId } = useParams();
	const [signMessage, setSignMessage] = useLocalStorage("signOption", true);
	const { user } = useAuthContext();
	const { setReplyingMessage, replyingMessage } = useReplyMessageContext();
	const { getSettingValue } = useSettingsContext();

	const isScheduleEnable = getSettingValue("showSchedulePage") === "true";
	const isForcedSignatureEnable =
		getSettingValue("forcedSignature") === "true" && user.profile === "user";

	const [medias, setMedias] = useState([]);
	const [inputMessage, setInputMessage] = useState("");
	const [showEmoji, setShowEmoji] = useState(false);
	const [showTemplates, setShowTemplates] = useState(false);
	const [loading, setLoading] = useState(false);
	const [recording, setRecording] = useState(false);
	const [typeBar, setTypeBar] = useState(false);
	const [onDragEnter, setOnDragEnter] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [integrants, setIntegrants] = useState([]);
	const [integrantsFiltered, setIntegrantsFiltered] = useState([]);
	const [isComment, setIsComment] = useState(false);

	const mixpanel = useMixpanel();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

	const verifiedTimeTheLastMessage = useCallback(async () => {
		if ((new Date() - new Date(ticket.lastMessageDate)) / (1000 * 60 * 60) > 24
			&& ticket.whatsapp && ticket.whatsapp.isOficial) {
			toast.info(`Você está respondendo um
				ticket que não teve interação nas últimas 24 horas. Por isso, envie um template antes.`, {
				autoClose: 10000
			}
			)
			setShowTemplates(true);
			return true;
		}

		try {
			const { data } = await api.get(`/messages/lastMessageNotFromMe/${ticket.contact.id}`);
			if ((!data
				|| (data.fromChannel === 'whatsapp_official'
					&& new Date() - new Date(data.createdAt)) / (1000 * 60 * 60) > 24
			) && ticket.whatsapp && ticket.whatsapp.isOficial) {
				toast.info(`O cliente ainda nao respondeu o seu template, aguarde.`)
				return true;
			}
			return false;

		} catch (error) {
			return false
		}
	}, [ticket])

	const getContactName = useCallback(async (value) => {
		const arraySeparateFromAt = value.split('@');
		const lastString = arraySeparateFromAt[arraySeparateFromAt.length - 2];

		if (value === '') {
			setIntegrantsFiltered([]);
		} else if (
			(
				integrants.length > 1 &&
				lastString &&
				lastString[lastString.length - 1] === ' '
			) || value === '@'
		) {

			setIntegrantsFiltered(integrants.filter((e) => e.contact?.name.toLowerCase()
				.startsWith(arraySeparateFromAt[arraySeparateFromAt.length - 1].toLowerCase())
				|| e.contact.number.toLowerCase().startsWith(arraySeparateFromAt[arraySeparateFromAt.length - 1]
					.toLowerCase())));

		} else {
			setIntegrantsFiltered([]);
		}
	}, [integrants]);

	const handleChangeInput = useCallback((e) => {
		let value = e.target.value;
		const isQuickAnswerSearch = value && value.indexOf("/") === 0;

		if (value === '@') value = ' @'

		setInputMessage(value);

		getContactName(value);

		if (!typeBar && isQuickAnswerSearch) {
			return setTypeBar(true);
		} else if (typeBar && !isQuickAnswerSearch) {
			return setTypeBar(false);
		}
	}, [getContactName, typeBar]);

	const handleQuickAnswersClick = useCallback(async (value) => {
		try {
			const { data } = await api.get(value.mediaUrl, {
				responseType: "blob",
			});
			const myFile = new File([data], value.mediaUrl);
			const selectedMedias = [myFile];
			setInputMessage(value.message);
			setMedias(selectedMedias);
			setTypeBar(false);
			mixpanel.track('Quick Answer Use')
		} catch {
			setInputMessage(value.message);
			setTypeBar(false);
		}
		if (!isMobile) inputRef.current.focus();
	}, [mixpanel]);

	const handleQuickMentionClick = useCallback((value) => {
		const stringSepareLastAt = inputMessage.split('@');
		delete stringSepareLastAt[stringSepareLastAt.length - 1];
		const message = stringSepareLastAt.join('@');

		setInputMessage(message + value);
		getContactName('');
	}, [getContactName, inputMessage]);

	const handleAddEmoji = useCallback((e) => {
		let emoji = e.native;
		setInputMessage((prevState) => prevState + emoji);
		if (!isMobile) inputRef.current.focus();
	}, []);

	const handleChangeMedias = useCallback((e) => {
		if (!e.target.files) {
			return;
		}
		const selectedMedias = Array.from(e.target.files);
		setMedias(selectedMedias);
	}, []);

	const handleInputPaste = useCallback((e) => {
		if (e.clipboardData.files[0]) {
			const selectedMedias = Array.from(e.clipboardData.files);
			setMedias(selectedMedias);
		}
	}, []);

	const handleInputDrop = useCallback((e) => {
		e.preventDefault();
		if (e.dataTransfer.files[0]) {
			const selectedMedias = Array.from(e.dataTransfer.files);
			setMedias(selectedMedias);
		}
	}, []);

	const handleUploadMedia = useCallback(async (e) => {
		setLoading(true);
		if (e) {
			e.preventDefault();
		}

		if (await verifiedTimeTheLastMessage()) {
			return setLoading(false);
		}

    let isMediaTooLarge = false;
    let invalidExtension = false;

    medias.forEach((media) => {
      const extension = path.extname(media.name);
      if (!extension) {
        invalidExtension = true;
      }

      if (media.size > 15 * 1024 * 1024) { // Tamanho máximo de 15 megabytes
        isMediaTooLarge = true;
      }
    });

    if (invalidExtension) {
      toast.warn("Arquivo sem extensão, por favor adicione um arquivo valido.", {
        className: classes.customToast,
      });
      setLoading(false);
      return;
    }

    if (isMediaTooLarge) {
      toast.warn("Arquivo maior que 15MB não será possível enviar. Reduza o tamanho do arquivo.", {
        className: classes.customToast,
      });
      setLoading(false);
      return;
    }

		const formData = new FormData();
		formData.append("fromMe", true);
		medias.forEach((media) => {
			formData.append("medias", media);
			formData.append("body", media?.name);
			formData.append("caption", inputMessage.trim());
		});

		if (replyingMessage) {
			formData.append("quotedMsg", JSON.stringify(replyingMessage));
		}

		try {
			await api.post(`/messages/${ticketId}`, formData);
		} catch (err) {
			toastError(err);
		}

		setInputMessage("");
		setShowEmoji(false);
		setLoading(false);
		setReplyingMessage(null);
		setMedias([]);
	}, [
		medias,
		ticketId,
		classes,
		inputMessage,
		replyingMessage,
		setReplyingMessage,
		verifiedTimeTheLastMessage
	]);

	const handleSendMessage = useCallback(async () => {
		if (inputMessage.trim() === "") return;
		setLoading(true);

		if (await verifiedTimeTheLastMessage()) {
			return setLoading(false);
		}

		const message = {
			read: 1,
			fromMe: true,
			body: isForcedSignatureEnable || signMessage
				? `*${user?.name}:*\n${inputMessage.trim()}`
				: inputMessage.trim(),
			quotedMsg: replyingMessage,
			userId: user.id
		};

		if (isComment) {
			message["mediaUrl"] = "comment";
		} else {
			message["mediaUrl"] = "";
		}

		try {
			await api.post(`/messages/${ticketId}`, message);
		} catch (err) {
			if (err.response.data.error.includes('PÁGINA')) {
				toast.error(err.response.data.error.toLowerCase())
			} else {
				toast.error('Whatsapp desconectado')
			}
		}

		getContactName('');
		setInputMessage("");
		setShowEmoji(false);
		setLoading(false);
		setReplyingMessage(null);
		if (!isMobile) inputRef.current.focus();
	}, [
		isComment,
		inputMessage,
		ticketId,
		replyingMessage,
		setReplyingMessage,
		signMessage,
		user,
		getContactName,
		verifiedTimeTheLastMessage,
		isForcedSignatureEnable
	]);

	const handleStartRecording = useCallback(async () => {
		setLoading(true);
		try {
			await navigator.mediaDevices.getUserMedia({ audio: true });
			await Mp3Recorder.start();
			setRecording(true);
			setLoading(false);
		} catch (err) {
			toastError(err);
			setLoading(false);
		}
	}, []);

	const handleUploadAudio = useCallback(async () => {
		setLoading(true);
		if (await verifiedTimeTheLastMessage()) {
			await Mp3Recorder.stop();
			setRecording(false);
			return setLoading(false);
		}
		try {
			console.time('mp3');
			const [, blob] = await Mp3Recorder.stop().getMp3();
			if (blob.size < 10000) {
				setLoading(false);
				setRecording(false);
				return;
			}
			console.timeEnd('mp3');

			const formData = new FormData();
			const filename = `${new Date().getTime()}.mp3`;
			formData.append("medias", blob, filename);
			formData.append("body", filename);
			formData.append("fromMe", true);

			await api.post(`/messages/${ticketId}`, formData);
			mixpanel.track('Audio Use', {
				"Action": "Sent"
			});
		} catch (err) {
			console.log('erro ao enviar audio', err);
			toastError(err);
		}

		setRecording(false);
		setLoading(false);
	}, [ticketId, verifiedTimeTheLastMessage, mixpanel]);

	const handleCancelAudio = useCallback(async () => {
		try {
			await Mp3Recorder.stop();
			setRecording(false);
		} catch (err) {
			toastError(err);
		}
	}, []);

	const handleOpenMenuClick = useCallback((event) => {
		setAnchorEl(event.currentTarget);
	}, []);

	const handleMenuItemClick = useCallback(() => {
		setAnchorEl(null);
	}, []);

	useEffect(() => {
		if (ticket.status !== "closed") {
			if (!isMobile) inputRef.current.focus();
		}
	}, [ticket.status]);

	useEffect(() => {
		if (!isMobile) {
			inputRef.current.focus();
			return () => {
				Mp3Recorder.stop();
			}
		}
	}, [isMobile]);

	useEffect(() => {
		if (!isMobile) {
			inputRef.current.focus();
		}
	}, [replyingMessage, isMobile]);

	useEffect(() => {
		if (onDragEnter) {
			setTimeout(() => {
				setOnDragEnter(false);
			}, 7000);
		}
	}, [onDragEnter]);

	// useEffect(() => {
	//   inputRef.current.focus();
	//   return () => {
	//     setInputMessage("");
	//     setShowEmoji(false);
	//     setMedias([]);
	//     setReplyingMessage(null);
	//   };
	// }, [ticketId, setReplyingMessage]);

	useEffect(() => {
		async function init() {
			if (ticket.whatsappId && ticket.isGroup) {
				const { data: { listContacts } } = await api
					.get(`/group/${ticket.contact.number}@g.us`, {
						params: {
							profilePicUrl: 'true',
							whatsappId: ticket.whatsappId
						}
					});
				setIntegrants(listContacts);
			}
		}
		init();
	}, [ticket]);

	const renderReplyingMessage = useCallback((message) => {
		return (
			<div className={classes.replyingMsgWrapper}>
				<div className={classes.replyingMsgContainer}>
					<span
						className={clsx(classes.replyingContactMsgSideColor, {
							[classes.replyingSelfMsgSideColor]: !message.fromMe,
						})}
					></span>
					<div className={classes.replyingMsgBody}>
						{!message.fromMe && (
							<span className={classes.messageContactName}>
								{message.contact?.name}
							</span>
						)}
						{message.body}
					</div>
				</div>
				<IconButton
					aria-label="showRecorder"
					disabled={
						loading || ticket?.status === "closed" || ticket?.status === "pending"
					}
					onClick={() => setReplyingMessage(null)}
				>
					<ClearIcon className={classes.sendMessageIcons} />
				</IconButton>
			</div>
		);
	}, [classes, loading, setReplyingMessage, ticket]);

	const mediaRender = useMemo(
		() => (
			<Paper
				elevation={0}
				square
				className={classes.viewMediaInputWrapper}
				onDragEnter={() => setOnDragEnter(true)}
				onDrop={(e) => handleInputDrop(e)}
			>
				<IconButton
					aria-label="cancel-upload"
					onClick={(e) => setMedias([])}
				>
					<CancelIcon className={classes.sendMessageIcons} />
				</IconButton>

				{loading ? (
					<div>
						<CircularProgress className={classes.circleLoading} />
					</div>
				) : (
					<Grid item className={classes.gridFiles}>
						<Typography variant="h6" component="div">
							{i18n.t("uploads.titles.titleFileList")} ({medias?.length})
						</Typography>
						<List>
							{medias?.map((value, index) => {
								return (
									<ListItem key={index}>
										<ListItemAvatar>
											<Avatar
												className={classes.avatar}
												alt={value?.name}
												src={URL.createObjectURL(value)}
											/>
										</ListItemAvatar>
										<ListItemText
											primary={`${value?.name}`}
											secondary={`${parseInt(value.size / 1024)} kB`}
										/>
									</ListItem>
								);
							})}
						</List>
						<InputBase
							style={{ width: "0", height: "0" }}
							inputRef={function (input) {
								if (input != null) {
									input.focus();
								}
							}}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									handleUploadMedia();
								}
							}}
							defaultValue={medias[0]?.name}
						/>
					</Grid>
				)}
				<IconButton
					aria-label="send-upload"
					onClick={handleUploadMedia}
					disabled={loading}
				>
					{
						loading
							? <CircularProgress size={28} />
							: <SendIcon className={classes.sendMessageIcons} />
					}
				</IconButton>
			</Paper>
		),
		[classes, medias, loading, handleUploadMedia, handleInputDrop]
	);

	const inputRender = useMemo(
		() => (
			<Paper
				square
				elevation={0}
				className={classes.mainWrapper}
				onDragEnter={() => setOnDragEnter(true)}
				onDrop={(e) => handleInputDrop(e)}
			>
				<TemplatesModal
					isOpen={showTemplates}
					onClose={() => setShowTemplates(false)}
					ticket={ticket}
					userId={user.id}
				/>
        <div className={onDragEnter ? classes.dropInfo : classes.dropInfoOut}>
          <span role="img" aria-label="down-icon">⬇️</span>
          {i18n.t("uploads.titles.titleUploadMsgDragDrop")}
          <span role="img" aria-label="down-icon">⬇️</span>
        </div>
        {replyingMessage && renderReplyingMessage(replyingMessage)}
        {integrantsFiltered.length > 0 && (
          <QuickMentionSelect
            handleClick={handleQuickMentionClick}
            integrantsFiltered={integrantsFiltered}
          />
        )}
        <div className={classes.newMessageBox}>
          <Hidden only={["md", "lg", "xl"]}>
            {showEmoji ? (
              <div className={classes.emojiBoxMobile}>
                <ClickAwayListener onClickAway={(e) => setShowEmoji(false)}>
                  <Picker
                    perLine={9}
                    showPreview={false}
                    showSkinTones={false}
                    onSelect={handleAddEmoji}
                  />
                </ClickAwayListener>
              </div>
            ) : null}
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleOpenMenuClick}
            >
              <MoreVert></MoreVert>
            </IconButton>
            <Menu
              id="simple-menu"
              keepMounted
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuItemClick}
            >
              <MenuItem onClick={handleMenuItemClick}>
                <IconButton
                  aria-label="emojiPicker"
                  className={classes.menuItemIconButton}
                  disabled={
                    loading ||
                    recording ||
                    ticket?.status === "closed"
                  }
                  onClick={(e) => setShowEmoji((prevState) => !prevState)}
                >
                  <MoodIcon className={classes.sendMessageIcons} />
                </IconButton>
              </MenuItem>
							<MenuItem onClick={handleMenuItemClick}>
								<input
									multiple
									type="file"
									id="upload-button"
									disabled={
										loading ||
										recording ||
										ticket?.status === "closed"
									}
									className={classes.uploadInput}
									onChange={handleChangeMedias}
								/>
								<TicketShareMenu
									channel={ticket.contact?.channel}
									ticketId={ticketId}
									isOficial={ticket.whatsapp && ticket.whatsapp.isOficial}
									whatsappId={ticket?.whatsappId}
									className={classes.menuItemIconButton}
									isMobile={isMobile}
								/>
							</MenuItem>
              {isScheduleEnable && (
                <MenuItem onClick={handleMenuItemClick}>
                  <IconButton
                    className={classes.menuItemIconButton}
                    aria-label="emojiPicker"
                    disabled={loading || recording || ticket?.status !== "open"}
                    onClick={handleOpenScheduleModal}
                  >
                    <CalendarToday className={classes.sendMessageIcons} />
                  </IconButton>
                </MenuItem>
              )}

							<MenuItem onClick={handleMenuItemClick}>
								<FormControlLabel
									style={{ marginRight: 7, color: "gray" }}
									label={i18n.t("messagesInput.signMessage")}
									labelPlacement="start"
									control={
										<Switch
											size="small"
											checked={signMessage}
											onChange={(e) => setSignMessage(e.target.checked)}
											name="showAllTickets"
											color="primary"
										/>
									}
								/>
							</MenuItem>

              <MenuItem onClick={handleMenuItemClick}>
                <FormControlLabel
                  style={{ marginRight: 7, color: "gray" }}
                  label="Anotar"
                  labelPlacement="start"
                  control={
                    <Switch
                      size="small"
                      checked={isComment}
                      onChange={() => setIsComment(!isComment)}
                      color="primary"
                    />
                  }
                />
              </MenuItem>
            </Menu>
          </Hidden>
          <div
            className={
              isComment === false
                ? classes.messageInputWrapper
                : classes.commentInputWrapper
            }
          >
            <InputBase
              inputRef={(input) => {
								if (!isMobile) {
									input && (inputRef.current = input);
								} else if (isMobile && input) {
									if (inputRef.current) input.blur();
									inputRef.current = undefined;
								}
              }}
              className={classes.messageInput}
              placeholder={
                ticket?.status === "open" || "groups"
                  ? i18n.t("messagesInput.placeholderOpen")
                  : i18n.t("messagesInput.placeholderClosed")
              }
              multiline
              maxRows={5}
              value={inputMessage}
              onChange={handleChangeInput}
              disabled={
                recording ||
                loading ||
                ticket?.status === "closed"
              }
              onPaste={(e) => {
                (ticket?.status === "open" || ticket?.status === "groups") &&
                  handleInputPaste(e);
              }}
              onKeyPress={(e) => {
                if (loading || e.shiftKey) return;
                else if (e.key === "Enter" && !isMobile) {
                  handleSendMessage();
                }
              }}
            />

						<Hidden only={["sm", "xs"]} className={classes.inputOptions}>
							{showEmoji ? (
								<div className={classes.emojiBox}>
									<ClickAwayListener onClickAway={(e) => setShowEmoji(false)}>
										<Picker
											perLine={16}
											showPreview={false}
											showSkinTones={false}
											onSelect={handleAddEmoji}
										/>
									</ClickAwayListener>
								</div>
							) : null}
							<div className={classes.iconsWrapper}>
								{ticket.whatsapp && ticket.whatsapp.isOficial && (
									<IconButton
										component="span"
										size="small"
										disabled={
											loading ||
											recording ||
											ticket?.status === "closed"
										}
										onClick={() => setShowTemplates(!showTemplates)}
									>
										<AppsTemplate />
									</IconButton>
								)}

                <IconButton
                  aria-label="emojiPicker"
                  size="small"
                  disabled={
                    loading ||
                    recording ||
                    ticket?.status === "closed"
                  }
                  onClick={(e) => setShowEmoji((prevState) => !prevState)}
                >
                  <MoodIcon className={classes.sendMessageIcons} />
                </IconButton>

                <input
                  multiple
                  type="file"
                  id="upload-button"
                  disabled={
                    loading ||
                    recording ||
                    ticket?.status === "closed"
                  }
                  className={classes.uploadInput}
                  onChange={handleChangeMedias}
                />

								<TicketShareMenu
									channel={ticket.contact?.channel}
									ticketId={ticketId}
									isOficial={ticket.whatsapp && ticket.whatsapp.isOficial}
									whatsappId={ticket?.whatsappId}
								/>

								{isScheduleEnable && (
									<IconButton
										aria-label="emojiPicker"
										size="small"
										disabled={loading || recording || ticket?.status !== "open"}
										onClick={handleOpenScheduleModal}
									>
										<CalendarToday className={classes.sendMessageIcons} />
									</IconButton>
								)}
							</div>

							{/* <CustomWildCardsDisplay ticketId={ticketId} /> */}
							<div className={classes.messageOptions}>
								{/* <FormControlLabel
                style={{ marginRight: 7, color: "gray" }}
                label={i18n.t("messagesInput.signMessage")}
                labelPlacement="end"
                control={
                  // <Checkbox
                  //   style={{ margin: 0, padding: 0, marginLeft: 23 }}
                  //   size="small"
                  //   checked={signMessage}
                  //   onChange={(e) => {
                  //     setSignMessage(e.target.checked);
                  //   }}
                  //   name="showAllTickets"
                  //   color="primary"
                  // />
                }
              /> */}

								<Button
									variant="outlined"
									size="small"
									disabled={isForcedSignatureEnable}
									className={
										signMessage
											? classes.activatedButton
											: classes.deactivatedButton
									}
									onClick={() => setSignMessage(!signMessage)}
								>
									{isForcedSignatureEnable ? (
										<>
											<LockOutlinedIcon fontSize="small" />
											<span>Assinar</span>
										</>
									) : (
										<span>Assinar</span>
									)}
								</Button>

								<Button
									variant="outlined"
									size="small"
									className={
										isComment
											? classes.activatedButton
											: classes.deactivatedButton
									}
									onClick={(e) => setIsComment(!isComment)}
								>
									Anotar
								</Button>
							</div>
						</Hidden>

						<QuickAnswerSelect
							handleClick={handleQuickAnswersClick}
							searchParam={inputMessage.substring(1)}
							isOpen={typeBar}
						/>
					</div>
					{inputMessage ? (
						<IconButton
							aria-label="sendMessage"
							onClick={handleSendMessage}
							disabled={loading}
						>
							{
								loading
									? <CircularProgress size={28} />
									: <SendIcon className={classes.sendMessageIcons} />
							}
						</IconButton>
					) : recording ? (
						<>
							<div className={classes.recorderOverlay} />
							<div className={classes.recorderWrapper}>
								<IconButton
									aria-label="cancelRecording"
									component="span"
									fontSize="large"
									disabled={loading}
									onClick={handleCancelAudio}
								>
									<HighlightOffIcon className={classes.cancelAudioIcon} />
								</IconButton>
								{loading ? (
									<div>
										<CircularProgress className={classes.audioLoading} />
									</div>
								) : (
									<RecordingTimer />
								)}

                <IconButton
                  aria-label="sendRecordedAudio"
                  component="span"
                  onClick={handleUploadAudio}
                  disabled={loading}
                >
                  <CheckCircleOutlineIcon className={classes.sendAudioIcon} />
                </IconButton>
              </div>
            </>
          ) : (
            <IconButton
              aria-label="showRecorder"
              disabled={
                loading ||
                ticket?.status === "closed"
              }
              onClick={handleStartRecording}
            >
              <MicIcon className={classes.initAudioIcon} />
            </IconButton>
          )}
        </div>
      </Paper>
    ),
    [
      classes,
      onDragEnter,
      handleInputDrop,
      replyingMessage,
      showEmoji,
      anchorEl,
      loading,
      handleChangeMedias,
      inputMessage,
      handleChangeInput,
      handleInputPaste,
      handleSendMessage,
      isComment,
      handleQuickAnswersClick,
      recording,
      handleCancelAudio,
      handleUploadAudio,
      handleStartRecording,
      handleOpenScheduleModal,
      integrantsFiltered,
      isScheduleEnable,
      renderReplyingMessage,
      setSignMessage,
      signMessage,
      ticket,
      ticketId,
      typeBar,
      handleQuickMentionClick,
      showTemplates,
      user,
      isForcedSignatureEnable,
      handleAddEmoji,
      handleMenuItemClick,
      handleOpenMenuClick,
			isMobile
    ]
  );

	return medias.length > 0 ? mediaRender : inputRender;
}

export default MessageInput;
