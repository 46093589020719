import { useEffect, useReducer } from "react";
import { parseCookies, setCookie } from "nookies";

import openSocket from "../../services/socket-io";
import { useAuthContext } from "../../context/Auth/AuthContext";
import { dataReducer } from "../../reducers/data";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const cookieOptions = {
  path: "/",
  domain: ".deskrio.com.br",
  secure: true,
  sameSite: "none",
}

function useCustomerNotices() {
  const [customerNotices, dispatchCustomerNotices] = useReducer(dataReducer, []);
  const { user, isAdminClient } = useAuthContext();

  const fetchNewNotices = async () => {
    try {
      const { data } = await api.get("/new-notices");
      dispatchCustomerNotices({ type: "LOAD_DATA", payload: data.notices });
    } catch (err) {
      toastError(err);
    }
  }

  const markNoticeAsRead = (noticeId) => {
    const { closedNotices } = parseCookies();

    if (closedNotices) {
      const noticeIds = new Set(JSON.parse(closedNotices));
      noticeIds.add(+noticeId);
      return setCookie(
        null,
        "closedNotices",
        JSON.stringify(Array.from(noticeIds)),
        cookieOptions
      );
    }

    setCookie(null, "closedNotices", JSON.stringify([noticeId]), cookieOptions)
  }

  useEffect(() => {
    if (!isAdminClient) fetchNewNotices();
  }, [isAdminClient]);

  useEffect(() => {
    const socket = openSocket({
      scope: "useCustomerNotices",
      userId: user.id,
      component: "useCustomerNotices",
    });

    socket.on("user", (data) => {
      if (data.action === "notice") {
        dispatchCustomerNotices({
          type: "UPDATE_DATA",
          payload: data.notice
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [customerNotices, user]);

  return { customerNotices, dispatchCustomerNotices, markNoticeAsRead };
}

export default useCustomerNotices;
