import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import {
  Button,
  Chip,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { DeleteOutline, Edit } from "@material-ui/icons";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import ExtrainfoFieldModal from "../../components/ExtrainfoFieldModal";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Extrainfos = () => {
  const classes = useStyles();
  const [fields, setFields] = useState([]);
  const [openFieldModal, setOpenFieldModal] = useState(false);
  const [selectedField, setSelectedField] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const { data: fields } = await api.get("/extrainfo/field");
        setFields(fields);
      } catch (error) {
        toastError(error);
      }
    })();
  }, [openFieldModal]);

  const handleDeleteField = async (fieldId) => {
    try {
      await api.delete(`/extrainfo/field/${fieldId}`);
      toast.success(`Campo deletado com sucesso!`);
      setFields(fields.filter((field) => field.id !== fieldId));
    } catch (err) {
      toastError(err);
    }
  };

  const translateContext = (context) => {
    switch (context) {
      case "contact":
        return "Contato";
      case "ticket":
        return "Ticket";
      default:
        return "";
    }
  };

  return (
    <MainContainer>
      {/* {openFieldModal && <ExtrainfoFieldModal />} */}
      <ExtrainfoFieldModal
        open={openFieldModal}
        selectedField={selectedField}
        setSelectedField={setSelectedField}
        onClose={() => setOpenFieldModal(false)}
      />
      <MainHeader>
        <Title>Campos Personalizados</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenFieldModal(true);
            }}
          >
            Adicionar campo
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        style={{ marginBottom: "5em" }}
      >
        <Typography variant="h6">Textuais</Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Descrição</TableCell>
              {/* <TableCell align="center">
                Configuração
              </TableCell> */}
              <TableCell align="center">Mascara</TableCell>
              <TableCell align="center">Exemplo</TableCell>
              <TableCell align="center">Contexto</TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {fields
                ?.filter((field) => field.type === "text")
                ?.map((field) => (
                  <TableRow key={field.id}>
                    <TableCell align="center">{field?.name}</TableCell>
                    <TableCell align="center">{field.description}</TableCell>
                    <TableCell
                      align="center"
                      style={{
                        maxWidth: "100px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {field.mask}
                    </TableCell>
                    {/* <TableCell align="center">{field.config}</TableCell> */}
                    <TableCell align="center">{field.example}</TableCell>
                    <TableCell align="center">
                      {translateContext(field.context)}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setSelectedField(field);
                          setOpenFieldModal(true);
                        }}
                      >
                        <Edit />
                      </IconButton>

                      <IconButton
                        size="small"
                        onClick={() => handleDeleteField(field.id)}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </>
          </TableBody>
        </Table>
      </Paper>

      <Paper
        className={classes.mainPaper}
        variant="outlined"
        style={{ marginBottom: "5em" }}
      >
        <Typography variant="h6">Opcionais</Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Descrição</TableCell>
              <TableCell align="center"></TableCell>
              {/* <TableCell align="center">
                Configuração
              </TableCell> */}
              <TableCell align="center">Opções</TableCell>
              <TableCell align="center">Contexto</TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {fields
                ?.filter(
                  (field) =>
                    field.type === "singleOption" ||
                    field.type === "multiOption"
                )
                ?.map((field) => (
                  <TableRow key={field.id}>
                    <TableCell align="center">{field?.name}</TableCell>
                    <TableCell align="center">{field.description}</TableCell>
                    <TableCell align="center">{""}</TableCell>
                    {/* <TableCell align="center">{field.config}</TableCell> */}
                    <TableCell align="center">
                      {field?.options?.map((option, index) => {
                        if (index === 5) {
                          return (
                            <span
                              style={{
                                textAlign: "center",
                              }}
                            >
                              ...
                            </span>
                          );
                        }
                        if (index > 5) {
                          return null;
                        }
                        return (
                          <Chip
                            key={option.id}
                            label={option.value}
                            variant="outlined"
                            size="small"
                            style={{
                              padding: 0,
                              margin: "0.3em",
                              fontSize: "0.8em",
                            }}
                          />
                        );
                      })}
                    </TableCell>
                    <TableCell align="center">
                      {translateContext(field.context)}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setSelectedField(field);
                          setOpenFieldModal(true);
                        }}
                      >
                        <Edit />
                      </IconButton>

                      <IconButton
                        size="small"
                        onClick={() => handleDeleteField(field.id)}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </>
          </TableBody>
        </Table>
      </Paper>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        style={{ marginBottom: "5em" }}
      >
        <Typography variant="h6">Mídias</Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Descrição</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">Contexto</TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {fields
                ?.filter((field) => field.type === "media")
                ?.map((field) => (
                  <TableRow key={field.id}>
                    <TableCell align="center">{field?.name}</TableCell>
                    <TableCell align="center">{field.description}</TableCell>
                    <TableCell align="center">{""}</TableCell>
                    <TableCell align="center">
                      {translateContext(field.context)}
                    </TableCell>
                    {/* <TableCell align="center">{field.config}</TableCell> */}
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setSelectedField(field);
                          setOpenFieldModal(true);
                        }}
                      >
                        <Edit />
                      </IconButton>

                      <IconButton
                        size="small"
                        onClick={() => handleDeleteField(field.id)}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Extrainfos;
