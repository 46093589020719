import React, { useCallback, useContext, useEffect, useState } from "react";

import { makeStyles, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { useHistory } from "react-router-dom";

import { useAuthContext } from '../../context/Auth/AuthContext';
import { useUsersContext } from '../../context/UsersContext';
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import toastError from "../../errors/toastError";
import getOpenTicket from "../../helpers/getOpenTicket";
import useMixpanel from "../../hooks/useMixpanel";
import useQueues from "../../hooks/useQueues";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { Can } from "../Can";
import ExistingTicketModal from "../ExistingTicketModal";

const useStyles = makeStyles((theme) => ({
  maxWidth: {
    width: "100%",
  },
	form: {
		width: "400px",
		[theme.breakpoints.down("xs")]: {
			width: "auto"
		}
	}
}));

const TransferTicketModal = ({
  modalOpen,
  onClose,
  ticketid,
  ticketWhatsappId,
  status,
  ticket,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { findAll: findAllQueues } = useQueues();
  const { users, pageNumber, setUsersPageNumber, fetchUsers } = useUsersContext();
  const { whatsApps, loading: loadingWhatsapps } = useContext(WhatsAppsContext);
  const { user: loggedInUser } = useAuthContext();

  const [queues, setQueues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedQueue, setSelectedQueue] = useState("");
  const [selectedWhatsapp, setSelectedWhatsapp] = useState(ticketWhatsappId);
  const [selectedMeta, setSelectedMeta] = useState("");
  const [metas, setMetas] = useState();
  const [existingTicketModalOpen, setExistingTicketModalOpen] = useState(false);
  const [existingTickedId, setExistingTickedId] = useState(0);

	const mixpanel = useMixpanel();

  const ticketChannel = ticket?.contact?.channel;


  const filteredUsers = users.filter(({ queues }) => {
    return queues.some(({ id }) => id === selectedQueue);
  });

  const getMetas = useCallback(async () => {
    try {
      const { data } = await api.get("/meta");
      const filterData = data.filter((item) => {
        if (ticketChannel === "instagram") {
          return item?.name.includes("- IG");
        } else {
          return !item?.name.includes("- IG");
        }
      });
      setMetas(filterData);
    } catch {
      console.log("Erro ao pegar meta conexões");
    }
  }, [ticketChannel]);

  const changeSetorUsers = (e) => {
    setSelectedQueue(e);
  };

  const handleClose = () => {
    onClose();
    setSelectedUser(null);
  };

  const handleSaveTicket = async (e) => {
    e.preventDefault();
    if (!ticketid) return;
    setLoading(true);
    try {
      let data = {};
      data.userId = null;

      data.status = "pending";

      if (selectedUser) {
        data.userId = selectedUser;
      }

      if (selectedQueue) {
        data.queueId = selectedQueue;

        if (!selectedUser) {
          data.status = "pending";
          data.userId = null;
        }
      }

      if (selectedWhatsapp) {
        const isMeta =
          ticket.contact.channel === "facebook" ||
          ticket.contact.channel === "instagram";
        if (!isMeta) {
          data.whatsappId = selectedWhatsapp;
          data.metaId = null;
        }
      }

      if (selectedMeta) {
        data.metaId = selectedMeta;
        data.whatsappId = null;
      }

      if (status === "groups") {
        data.status = "groups";
      }
      await api.put(`/tickets/${ticketid}`, {...data, actionNameUser: loggedInUser.name});
			mixpanel.track('Ticket Change', {
				"Action": "Transfer Ticket",
			});
      setLoading(false);
      history.push(`/tickets`);
    } catch (err) {
      console.log(err);
      // TODO: procurar onde o back-end quebra para tratar específicamente o caso de tickets duplicados
      const ticketExists = await getOpenTicket(
        selectedMeta,
        ticket.contactId,
        selectedWhatsapp,
        selectedUser
      );
      if (ticketExists) {
        setExistingTickedId(ticketExists.id);
        setExistingTicketModalOpen(true);
        setLoading(false);
        return;
      }

      setLoading(false);
      toastError(err);
    }
  };

  useEffect(() => {
    if (!pageNumber) setUsersPageNumber(1);
  }, [pageNumber, setUsersPageNumber]);

  useEffect(() => {
    getMetas();
  }, [modalOpen, getMetas]);

  useEffect(() => {
    const loadQueues = async () => {
      const list = await findAllQueues();
      setQueues(list);
    };
    loadQueues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

	useEffect(() => {
		(async () => {
			if (modalOpen) {
				fetchUsers();
			}
		})()
	}, [modalOpen, fetchUsers])

  return (
    <Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
      <ExistingTicketModal
        modalOpen={existingTicketModalOpen}
        onClose={() => {
          setExistingTicketModalOpen(false);
          handleClose();
        }}
        ticketId={existingTickedId}
      />

      <form onSubmit={handleSaveTicket} className={classes.form}>
        <DialogTitle id="form-dialog-title">
          {i18n.t("transferTicketModal.title")}
        </DialogTitle>
        <DialogContent
          dividers
          style={{ display: "flex", flexDirection: "column", gap: "1em" }}
        >
          <FormControl variant="outlined" className={classes.maxWidth}>
            <InputLabel>
              {i18n.t("transferTicketModal.fieldQueueLabel")}
            </InputLabel>
            <Select
              value={selectedQueue}
              onChange={(e) => changeSetorUsers(e.target.value)}
              label={i18n.t("transferTicketModal.fieldQueuePlaceholder")}
            >
              <MenuItem value={""}>&nbsp;</MenuItem>
              {queues.map((queue) => (
                <MenuItem key={queue.id} value={queue.id}>
                  {queue?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {status !== "groups" && (
            <FormControl variant="outlined" className={classes.maxWidth}>
              <InputLabel>Transferir para usuário</InputLabel>
              <Select
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
                label={i18n.t("transferTicketModal.fieldQueuePlaceholder")}
              >
                <MenuItem value={""}>&nbsp;</MenuItem>
                {filteredUsers?.map((user) => (
                  <MenuItem
										key={user.id} value={user.id}
										style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}
									>
                    <span
											style={{color: user.isOnline ? "green" : "red"}}
										>
											●
										</span>
										{user?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {ticket.contact.channel === "facebook" ||
            ticket.contact.channel === "instagram" ? (
            <Can
              role={loggedInUser.profile}
              perform="ticket-options:transferWhatsapp"
              yes={() =>
                !loadingWhatsapps && (
                  <FormControl
                    variant="outlined"
                    className={classes.maxWidth}
                    style={{ marginTop: 20 }}
                  >
                    <InputLabel>
                      {i18n.t("transferTicketModal.fieldConnectionLabel")}
                    </InputLabel>
                    <Select
                      value={selectedMeta}
                      onChange={(e) => setSelectedMeta(e.target.value)}
                      label={i18n.t(
                        "transferTicketModal.fieldConnectionPlaceholder"
                      )}
                    >
                      {metas.map((meta) => (
                        <Tooltip title="Garanta que esta conversa já exista nesta conexão">
                          <MenuItem key={meta.id} value={meta.id}>
                            {meta?.name}
                          </MenuItem>
                        </Tooltip>
                      ))}
                    </Select>
                  </FormControl>
                )
              }
            />
          ) : (
            <Can
              role={loggedInUser.profile}
              perform="ticket-options:transferWhatsapp"
              yes={() =>
                !loadingWhatsapps && (
                  <FormControl
                    variant="outlined"
                    className={classes.maxWidth}
                    style={{ marginTop: 20 }}
                  >
                    <InputLabel>
                      {i18n.t("transferTicketModal.fieldConnectionLabel")}
                    </InputLabel>
                    <Select
                      value={selectedWhatsapp}
                      onChange={(e) => setSelectedWhatsapp(e.target.value)}
                      label={i18n.t(
                        "transferTicketModal.fieldConnectionPlaceholder"
                      )}
                    >
                      {whatsApps.filter((whatsapp) => whatsapp.status !== "INATIVE").map((whasapp) => (
                        <MenuItem key={whasapp.id} value={whasapp.id}>
                          {whasapp?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )
              }
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            disabled={loading}
            variant="outlined"
          >
            {i18n.t("transferTicketModal.buttons.cancel")}
          </Button>
          <ButtonWithSpinner
            variant="contained"
            type="submit"
            color="primary"
            loading={loading}
          >
            {i18n.t("transferTicketModal.buttons.ok")}
          </ButtonWithSpinner>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TransferTicketModal;
