import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
  },

  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
  },

  settingOption: {
    marginLeft: "auto",
  },

  margin: {
    margin: theme.spacing(1),
  },

  color: {
    color: "#0241AD",
  },

  text: {
    marginLeft: "42px",
  },

  textP: {
    marginLeft: "42px",
  },
}));

const Api = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <h2>Documentação para envio de mensagens</h2>

        <h2 className={classes.color}>Métodos de Envio</h2>

        <p className={classes.text}>1. Mensagens de Texto</p>
        <p className={classes.text}>2. Mensagens de Mídia</p>

        <h2 className={classes.color}>Instruções</h2>
        <p>
          <b>Observações Importantes</b>
        </p>
        <ul>
          <li>
            Para pegar o token da API, vá em configurações que seu token estará la, sem
            ele não será possível enviar mensagens.
          </li>
          <li>
            O número para envio não deve ter mascara ou caracteres especiais e deve ser
            composto por:
          </li>
          <br />
          <ol>
            <ul>
              <li>Código do pais - Ex: 55 (Brasil)</li>
              <li>DDD</li>
              <li>Número</li>
            </ul>
          </ol>
        </ul>
        <h2 className={classes.color}>1. Mensagens de Texto</h2>
        <p>
          Seguem abaixo lista de informações necessárias para envio das mensagens de
          texto:
        </p>
        <p className={classes.textP}>
          <b>URL: </b>
          {process.env.REACT_APP_BACKEND_URL}/v1/api/messages/send
        </p>
        <p className={classes.textP}>
          <b>Metódo: </b>POST
        </p>
        <p className={classes.textP}>
          <b>Headers: </b>Authorization: Bearer (token) e Content-Type application/json
        </p>
        <p className={classes.textP}>
          <b>Body: </b>
          <br/>
          <code>
            "number": "552139006391", <br></br>
            "body": "Texto",<br></br>
            "whatsappId": ID do whatsapp, <br></br>
            "queueId": ID do setor, <br></br>
            "userId": "ID do Usuário" <br></br>
          </code>
        </p>
        <br></br>

        <h2 className={classes.color}>2. Mensagens de Mídia</h2>
        <p>Seguem abaixo lista de informações necessárias para envio de mídias:</p>
        <p className={classes.textP}>
          <b>URL: </b>
          {process.env.REACT_APP_BACKEND_URL}/v1/api/messages/send
        </p>
        <p className={classes.textP}>
          <b>Método: </b>POST
        </p>
        <p className={classes.textP}>
          <b>Headers: </b>Authorization: Bearer (token) e Content-Type multipart/form-data
        </p>
        <p className={classes.textP}>
          <b>Body: </b>
          <br />
          <code>
            "number": "5599999999999", <br></br>
            "medias": "aqui vai sua midia",<br></br>
            "body": "Enviado via api" <br></br>
          </code>
        </p>
        <br></br>
      </Container>
    </div>
  );
};

export default Api;
