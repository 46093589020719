import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import RestoreIcon from "@material-ui/icons/Restore";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import Drawer from "@material-ui/core/Drawer";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import CreateIcon from "@material-ui/icons/Create";
import { RefreshRounded } from "@material-ui/icons";
import ModalImage from "react-modal-image";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Card,
  CardContent,
  Divider,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import { useParams } from "react-router-dom";

import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import MarkdownWrapper from "../MarkdownWrapper";
import { ContactForm } from "../ContactForm";
import ContactModal from "../ContactModal";
import api from "../../services/api";
import CopyToClipboard from "../CopyToClipboard";
import { useAuthContext } from "../../context/Auth/AuthContext";
import ImageModal from "../ImageModal";
import { ExtraFieldsForm } from "./ExtraFieldsForm";
import useMixpanel from "../../hooks/useMixpanel";
import { useStyles } from "./styles";

const ContactDrawer = ({
  open,
  handleDrawerClose,
  contact,
  setContact,
  loading,
}) => {
  const classes = useStyles();
  const { ticketId } = useParams();
  const mixpanel = useMixpanel();
  const { users, user } = useAuthContext();

  const [modalOpen, setModalOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [fields, setFields] = useState([]);
  const [tab, setTab] = useState(0);
  const [contactInfos, setContactInfos] = useState(contact.extraInfo || []);
  const [userActions, setUserActions] = useState([]);
  const [actionsPage, setActionsPage] = useState(1);
  const [hasMoreActions, setHasMoreActions] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [disableRefresh, setDisableRefresh] = useState(false);
  const [ticketIds, setTicketIds] = useState([]);
  const [motiveFinish, setMotiveFinish] = useState({});
  const [dateOfCreation, setDateOfCreation] = useState({});
  const [tickets, setTickets] = useState(null);

  const URL = window.location.href;

  useEffect(() => {
    setDateOfCreation({});
    setMotiveFinish({});
    setTickets(null);
  }, [contact.id]);

  useEffect(() => {
    if (!open) return;
    const takeUserActions = async () => {
      const { data } = await api.get(`/user-actions/${contact.id}`, {
        params: { pageNumber: actionsPage },
      });
      setHasMoreActions(data.hasMore);
      setUserActions((prevState) =>
        [...prevState, ...data.userActions].sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        })
      );
    };
    takeUserActions();
  }, [contact, actionsPage, open]);

  useEffect(() => {
    setActionsPage(1);

    const ticketIdsArr = [];

    if (contact?.extraInfo) {
      contact.extraInfo.forEach((extraInfo) => {
        if (!ticketIdsArr.includes(extraInfo.ticketId)) {
          ticketIdsArr.push(extraInfo.ticketId);
        }
      });
    }

    setTicketIds(ticketIdsArr);
  }, [contact]);

  useEffect(() => {
    setContactInfos(contact.extraInfo || []);
  }, [contact, handleDrawerClose]);

  useEffect(() => {
    setOpenForm(false);
  }, [open, contact]);

  useEffect(() => {
    setSelectedUser(contact.userId ?? "");
  }, [contact]);

  const updateClientPocketing = async (userId) => {
    const data =
      userId !== undefined
        ? { ...contact, userId }
        : { ...contact, userId: null };

    if (contact.id) {
      await api.put(`/contacts/${contact.id}`, data);
      mixpanel.track(`Contact Attendant Change`, {
        "Contact Name": contact.name,
        Origin: `${URL.split("/")[3]}`,
      });
    }
  };

  useEffect(() => {
    (async () => {
      const { data: fields } = await api.get("/extrainfo/field");
      setFields(fields);
    })();
  }, []);

  const loadMore = () => {
    setActionsPage((prevState) => prevState + 1);
  };

  const handleScrollActions = (e) => {
    if (!hasMoreActions) return;

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const addZero = (number) => {
    if (number.length < 2) {
      number = "0" + number;
    }
    return number;
  };

  const defOrigin = (action) => {
    if (action && action.fromUser === "ChatBot") {
      return "Potencial";
    } else if (action && action.action === "create" && action.fromUser) {
      return "Ativo";
    } else if (action && action.action === "create" && !action.fromUser) {
      return "Reativo";
    }
  };

  const generateTimeline = (unique) => {
    let userActionsFiltered = unique
      ? userActions?.filter((action) => {
          return action.ticketId?.toString() === ticketId?.toString();
        })
      : userActions;

    // remove duplicated actions by id
    userActionsFiltered = userActionsFiltered.filter(
      (action, index, self) =>
        index === self.findIndex((t) => t.id === action.id)
    );

    const actions = userActionsFiltered
      ?.slice(0)
      .reverse()
      .map((action) => {
        var utcDate = action.createdAt;
        var localDate =
          addZero(new Date(utcDate).getDate().toString()) +
          "/" +
          addZero((new Date(utcDate).getMonth() + 1).toString()) +
          "/" +
          addZero(new Date(utcDate).getFullYear().toString()) +
          " - " +
          addZero(new Date(utcDate).getHours().toString()) +
          ":" +
          addZero(new Date(utcDate).getMinutes().toString());

        const origin = defOrigin(action);

        if (action.action.includes("mass closing")) {
          return (
            <div className={classes.actionArea}>
              <p
                className={classes.actionTicket}
              >{`Ação em massa - tickets:  ${action.massActions
                .filter(
                  (massAction) => massAction.ticket.contactId === contact.id
                )
                .map((massAction) => {
                  return ` ${massAction.ticketId}`;
                })}`}</p>
              <p className={classes.actionAct}>
                {`Ticket finalizado em massa por ${action.fromUser}`}{" "}
              </p>
              <i className={classes.actionDate}>{localDate}</i>
              <i className={classes.actionDate}>{}</i>
            </div>
          );
        }
        if (action.action.includes("ramal")) {
          return (
            <div className={classes.actionArea}>
              <p
                className={classes.actionTicket}
              >{`ticket ${action.ticketId}`}</p>
              <p className={classes.actionAct}>
                {`Ticket transferido via RAMAL para ${action.toUser}`}{" "}
              </p>
              <i className={classes.actionDate}>{localDate}</i>
              <i className={classes.actionDate}>{}</i>
            </div>
          );
        }
        if (action.action.includes("pending -> open")) {
          return (
            <div className={classes.actionArea}>
              <p
                className={classes.actionTicket}
              >{`ticket ${action.ticketId}`}</p>
              <p className={classes.actionAct}>
                {`Ticket obtido por ${action.toUser}`}{" "}
              </p>
              <i className={classes.actionDate}>{localDate}</i>
              <i className={classes.actionDate}>{}</i>
            </div>
          );
        }
        if (!action.toUser && action.action.includes("open -> pending")) {
          return (
            <div className={classes.actionArea}>
              <p
                className={classes.actionTicket}
              >{`ticket ${action.ticketId}`}</p>
              <p className={classes.actionAct}>
                {`Ticket devolvido por ${action.fromUser}`}{" "}
              </p>
              <i className={classes.actionDate}>{localDate}</i>
            </div>
          );
        }
        if (action.action.includes("closed -> open") && action.fromUser) {
          return (
            <div className={classes.actionArea}>
              <p
                className={classes.actionTicket}
              >{`ticket ${action.ticketId}`}</p>
              <p className={classes.actionAct}>
                {`Ticket reaberto por ${action.fromUser}`}{" "}
              </p>
              <i className={classes.actionDate}>{localDate}</i>
            </div>
          );
        }
        if (action.action.includes("-> closed") && action.fromUser) {
          return (
            <div className={classes.actionArea}>
              <p
                className={classes.actionTicket}
              >{`ticket ${action.ticketId}`}</p>
              <p className={classes.actionAct}>
                {`Ticket fechado por ${action.fromUser}`}{" "}
              </p>
              <i className={classes.actionDate}>{localDate}</i>
            </div>
          );
        }
        if (
          action.action.includes("update") &&
          action.fromUser &&
          action.fromUser !== action.toUser
        ) {
          return (
            <div className={classes.actionArea}>
              <p
                className={classes.actionTicket}
              >{`ticket ${action.ticketId}`}</p>
              <p className={classes.actionAct}>
                {`Transferência ${action.fromUser} -> ${action.toUser}`}{" "}
              </p>
              <i className={classes.actionDate}>{localDate}</i>
            </div>
          );
        }
        if (action.action === "create" && action.fromUser) {
          return (
            <div className={classes.actionArea}>
              <p
                className={classes.actionTicket}
              >{`ticket ${action.ticketId}`}</p>
              <p className={classes.actionAct}>
                {`Ticket criado por ${action.fromUser}`}{" "}
              </p>
              <div className={classes.actionFooter}>
                <i className={classes.actionDate}>{localDate}</i>
                <i className={classes.actionDate}>{origin}</i>
              </div>
            </div>
          );
        }
        if (action.action === "create" && !action.fromUser) {
          return (
            <div className={classes.actionArea}>
              <p
                className={classes.actionTicket}
              >{`ticket ${action.ticketId}`}</p>
              <p className={classes.actionAct}>
                {`Ticket criado pelo cliente`}{" "}
              </p>
              <div className={classes.actionFooter}>
                <i className={classes.actionDate}>{localDate}</i>
                <i className={classes.actionDate}>{origin}</i>
              </div>
            </div>
          );
        }
        if (action.action === "delete") {
          return (
            <div className={classes.actionArea}>
              <p
                className={classes.actionTicket}
              >{`ticket ${action.ticketId}`}</p>
              <p className={classes.actionAct}>
                {`Ticket deletado por ${action.fromUser}`}{" "}
              </p>
              <i className={classes.actionDate}>{localDate}</i>
            </div>
          );
        }
        if (action.action === "preview") {
          return (
            <div className={classes.actionArea}>
              <p
                className={classes.actionTicket}
              >{`ticket ${action.ticketId}`}</p>
              <p className={classes.actionAct}>
                {`Ticket visualizado por ${action.fromUser}`}{" "}
              </p>
              <i className={classes.actionDate}>{localDate}</i>
            </div>
          );
        }
        return null;
      });
    return actions;
  };

  const getProfileContact = async (id) => {
    setDisableRefresh(true);
    try {
      await api.get(`refresh-pic/${id}`);
    } catch {
      console.log("Impossível obter imagem de perfil");
    }
    setDisableRefresh(false);
  };

  const getMotiveFinish = useCallback(async (protocol) => {
    try {
      const { data } = await api.post("/end-tickets-filters-all", {
        protocol: `${protocol}`,
      });

      if (data.tickets[0].endTicket[0]) {
        return `${data.tickets[0].endTicket[0].option}`;
      }
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  const getDateAndTime = useCallback(
    async (ticket) => {
      if (ticket) {
        const dateObj = new Date(ticket?.createdAt);
        return {
          date: `${dateObj.toLocaleDateString()} - ${dateObj.toLocaleTimeString()}`,
          motive: await getMotiveFinish(ticket?.id),
        };
      }
      return {};
    },
    [getMotiveFinish]
  );

  useEffect(() => {
    (async () => {
      try {
        if (!tickets) {
          const { data } = await api.get(`/tickets-contact/${contact.id}`);
          if (data.length > 0) setTickets(data);
        }
      } catch (error) {
        console.log("error", error);
      }
    })();

    (async () => {
      if (tickets) {
        let objDate = {};
        let objMotive = {};

        await Promise.all(
          ticketIds
            .sort(function (a, b) {
              return b - a;
            })
            .map(async (tktId) => {
              if (tktId && tktId !== ticketId) {
                const { date, motive } = await getDateAndTime(
                  tickets?.find((ticket) => ticket.id === Number(tktId))
                );

                if (date) {
                  objDate = {
                    ...objDate,
                    [tktId]: date,
                  };
                }

                if (motive) {
                  objMotive = {
                    ...objMotive,
                    [tktId]: motive,
                  };
                }
              }
            })
        );

        setDateOfCreation(objDate);
        setMotiveFinish(objMotive);
      }
    })();
  }, [contact.id, tickets, ticketIds, ticketId, getDateAndTime, setContact]);

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      PaperProps={{ style: { position: "absolute" } }}
      BackdropProps={{ style: { position: "absolute" } }}
      ModalProps={{
        container: document.getElementById("drawer-container"),
        style: { position: "absolute" },
      }}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.header}>
        <IconButton
          onClick={() => {
            handleDrawerClose();
          }}
          style={{ position: "absolute" }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          style={{
            margin: "auto",
            fontWeight: "500",
            textTransform: "uppercase",
          }}
        >
          Informações de contato
        </Typography>
      </div>
      {loading ? (
        <ContactDrawerSkeleton classes={classes} />
      ) : (
        <div className={classes.content}>
          <Card className={classes.contactCard}>
            {contact.profilePicUrl ? (
              <ModalImage
                className={classes.cover}
                smallSrcSet={`${contact.profilePicUrl}` || `/user.png`}
                medium={`${contact.profilePicUrl}` || `/user.png`}
                large={`${contact.profilePicUrl}` || `/user.png`}
                showRotate={true}
              />
            ) : (
              <ModalImage
                className={classes.cover}
                smallSrcSet={`/user.png`}
                medium={`/user.png`}
                large={`/user.png`}
              />
            )}
            <Tooltip title="Atualizar Perfil">
              <IconButton
                disabled={disableRefresh}
                onClick={() => getProfileContact(ticketId)}
                style={{
                  position: "absolute",
                  bottom: "0px",
                  left: "135px",
                  padding: 0,
                }}
              >
                <RefreshRounded
                  style={{
                    fontSize: 30,
                    color: "white",
                    background: "#0009",
                    borderRadius: "50%",
                  }}
                />
              </IconButton>
            </Tooltip>
            <div className={classes.details}>
              <CardContent className={classes.cardContent}>
                <Typography
                  style={{ whiteSpace: "break-spaces" }}
                  component="h6"
                  variant="h6"
                >
                  {contact?.name.slice(0, 18) || ""}
                  <Tooltip title="Editar contato">
                    <CreateIcon
                      onClick={() => setModalOpen(true)}
                      style={{ fontSize: 16, marginLeft: 5, cursor: "pointer" }}
                    />
                  </Tooltip>
                  <CopyToClipboard content={contact?.name} title="nome" />
                </Typography>
                <Typography variant="subtitle1" color="primary">
                  {contact.number || ""}

                  <CopyToClipboard content={contact.number} title="número" />
                </Typography>
                <Divider style={{ margin: "8px" }} />
                <InputLabel
                  style={{
                    fontSize: "0.8rem",
                    color: "#000",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Atendente recorrente
                </InputLabel>
                <Typography
                  component="div"
                  noWrap
                  style={{
                    paddingTop: 2,
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  {user.profile === "admin" ||
                  !contact.userId ||
                  contact.userId === user.id ? (
                    <Select
                      variant="outlined"
                      value={selectedUser}
                      onChange={(e) => {
                        setSelectedUser(e.target.value);
                      }}
                      style={{
                        paddingTop: 2,
                        minWidth: "50%",
                        textAlign: "center",
                        fontSize: "0.8rem",
                        height: "3em",
                        width: "100%",
                      }}
                    >
                      <MenuItem
                        key={user.id}
                        value=""
                        onClick={() => updateClientPocketing(null)}
                      >
                        Não atribuido
                      </MenuItem>
                      {user.profile === "admin"
                        ? users.users?.map((user) => (
                            <MenuItem
                              key={user.id}
                              value={user.id}
                              onClick={() => updateClientPocketing(user.id)}
                            >
                              {user?.name}
                            </MenuItem>
                          ))
                        : users.users
                            ?.filter((userFilter) => user.id === userFilter.id)
                            .map((user) => (
                              <MenuItem
                                key={user.id}
                                value={user.id}
                                onClick={() => updateClientPocketing(user.id)}
                              >
                                {user?.name}
                              </MenuItem>
                            ))}
                    </Select>
                  ) : (
                    <MarkdownWrapper>{`${
                      contact.userId
                        ? users.users?.find(
                            (user) => user.id === contact.userId
                          )?.name
                        : "Não"
                    }`}</MarkdownWrapper>
                  )}
                </Typography>
              </CardContent>
            </div>
          </Card>

          {contact.id && openForm && (
            <ContactForm
              initialContact={contact}
              onCancel={() => setOpenForm(false)}
            />
          )}
          {/* </Paper> */}
          <Paper className={classes.contactDetails}>
            <ContactModal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              contactId={contact.id}
            ></ContactModal>

            {tab === 0 && (
              // EXTRA FIELDS
              <>
                <h4 className={classes.ticketHistoryTitle}>
                  Campos personalizados do contato
                </h4>

                <ExtraFieldsForm
                  contact={contact}
                  setContact={setContact}
                  fields={fields.filter(
                    (field) =>
                      field.context === "contact" || field.context === ""
                  )}
                  setFields={setFields}
                  contactInfos={contactInfos}
                  setContactInfos={setContactInfos}
                  contactDraweOpen={open}
                />

                <h4 className={classes.ticketHistoryTitle}>
                  {`Campos personalizados do ticket ${ticketId}`}
                </h4>

                <ExtraFieldsForm
                  contact={{
                    ...contact,
                    extraInfo: contact?.extraInfo?.filter(
                      (info) => info.ticketId == ticketId
                    ),
                  }}
                  setContact={setContact}
                  fields={fields.filter((field) => field.context === "ticket")}
                  setFields={setFields}
                  contactInfos={contactInfos.filter(
                    (info) => info.ticketId === ticketId
                  )}
                  setContactInfos={setContactInfos}
                  ticketId={ticketId}
                  contactDraweOpen={open}
                />

                {ticketIds.length > 0 && (
                  <h4 className={classes.ticketHistoryTitle}>
                    {`Campos personalizados dos tickets anteriores`}
                  </h4>
                )}

                {ticketIds
                  .sort(function (a, b) {
                    return b - a;
                  })
                  .map((tktId) => {
                    if (tktId && tktId !== ticketId) {
                      return (
                        <Box key={tktId} className={classes.extraInfoBlock}>
                          <Typography
                            className={classes.extraInfoName}
                          >{`Ticket ${tktId}:`}</Typography>
                          <Typography>
                            Data de criação:{" "}
                            {dateOfCreation[tktId] || "Carregando..."}
                          </Typography>
                          {contact?.extraInfo?.map((extraInfo) => {
                            if (extraInfo.ticketId === tktId) {
                              return (
                                <Fragment key={extraInfo.id}>
                                  {extraInfo?.path ? (
                                    <Box display="flex" alignItems="center">
                                      {/* name captilize */}
                                      <Typography>{`${extraInfo?.name}: `}</Typography>
                                      <ImageModal imageUrl={extraInfo?.path} />
                                      <Typography>{`${extraInfo?.value}`}</Typography>
                                    </Box>
                                  ) : (
                                    <Typography>{`${extraInfo?.name}: ${extraInfo?.value}`}</Typography>
                                  )}
                                </Fragment>
                              );
                            }
                            return null;
                          })}
                          <Typography>
                            Motivo de finalização:{" "}
                            {motiveFinish[tktId] || "N/A"}
                          </Typography>
                          <Divider className={classes.extraInfoDivider} />
                        </Box>
                      );
                    }
                    return null;
                  })}
              </>
            )}

            {tab === 1 && (
              // TICKET ACTIONS
              <>
                <div className={classes.actionsBox}>
                  <h3 className={classes.ticketHistoryTitle}>
                    Histórico do ticket
                  </h3>
                  <Box
                    className={classes.actionsDiv}
                    onScroll={handleScrollActions}
                  >
                    {userActions ? generateTimeline(true) : null}
                  </Box>
                </div>
              </>
            )}

            {tab === 2 && (
              // CONTACT ACTIONS
              <div className={classes.actionsBox}>
                <h3 className={classes.ticketHistoryTitle}>
                  Histórico do contato
                </h3>
                <Box
                  className={classes.actionsDiv}
                  onScroll={handleScrollActions}
                >
                  {userActions && user.profile === "admin"
                    ? generateTimeline(false)
                    : null}
                </Box>
              </div>
            )}
          </Paper>

          <BottomNavigation
            value={tab}
            onChange={(event, newValue) => {
              setTab(newValue);
            }}
            showLabels
            className={classes.bottomNav}
          >
            <BottomNavigationAction
              label="Campos Personalizados"
              icon={<ContactPhoneIcon />}
            />
            <BottomNavigationAction
              label="Histórico do Ticket"
              icon={<RestoreIcon />}
            />
            <BottomNavigationAction
              label="Histórico do Contato"
              icon={<MenuBookIcon />}
            />
          </BottomNavigation>
        </div>
      )}
    </Drawer>
  );
};

export default ContactDrawer;
