import { useState, useEffect } from "react";
import { getHoursCloseTicketsAuto } from "../../config";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const useTickets = ({
  // searchParam,
  pageNumber,
  status,
  date,
  showAll,
  queueIds,
  userIds,
  withUnreadMessages,
  filters,
  tab,
  order,
  column,
  selectedUsersIds,
  changeIsFixed,
}) => {
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setLoading(true);
    const fetchTickets = async () => {
      let data;
      try {
        const res = await api.get("/tickets", {
          params: {
            // searchParam,
            pageNumber,
            status,
            date,
            showAll,
            queueIds,
            userIds,
            withUnreadMessages,
            filters,
            order,
            column,
            selectedUsersIds,
          },
        });
        data = res.data;
        if (pageNumber !== 1) {
          setTickets([...tickets, ...data.tickets]);
        } else {
          setTickets(data.tickets);
        }

        let horasFecharAutomaticamente = getHoursCloseTicketsAuto();

        if (
          status === "open" &&
          horasFecharAutomaticamente &&
          horasFecharAutomaticamente !== "" &&
          horasFecharAutomaticamente !== "0" &&
          Number(horasFecharAutomaticamente) > 0
        ) {
          let dataLimite = new Date();
          dataLimite.setHours(
            dataLimite.getHours() - Number(horasFecharAutomaticamente)
          );

          data.tickets.forEach((ticket) => {
            if (ticket.status !== "closed") {
              let dataUltimaInteracaoChamado = new Date(ticket.lastMessageDate);
              if (dataUltimaInteracaoChamado < dataLimite) closeTicket(ticket);
            }
          });
        }

        setHasMore(data.hasMore);
				setCount(data.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toastError(err);
      }
    };

    const closeTicket = async (ticket) => {
      await api.put(`/tickets/${ticket.id}`, {
        status: "closed",
        userId: ticket.userId || null,
      });
    };

    fetchTickets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // searchParam,
    pageNumber,
    status,
    date,
    showAll,
    queueIds,
    userIds,
    withUnreadMessages,
    filters,
    order,
    selectedUsersIds,
    changeIsFixed,
  ]);

  return { tickets, loading, hasMore, count };
};

export default useTickets;
