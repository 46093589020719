import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
    minWidth: "152px",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
	quill: {
		height: '270px',
		'& .ql-snow .ql-tooltip': {
			left: '50% !important',
  		transform: 'translateX(-50%) !important',
		}
	}
}));
